import React, { useState, useEffect } from "react";
import {
  //getToken,
  //xhr,
  greetingsWords,
} from "../utils";
const localStorage = require("local-storage");

const BreadCumb = (props) => {
  //const token = getToken();
  const [userLogin, setUserLogin] = useState("");
  let greetings = greetingsWords();

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    const userInfo = localStorage.get("user-info");
    setUserLogin(userInfo);
    // get list inbound outstanding po
    //const userLoginRes = await xhr.get('active', '/user-login', {headers: {'Authorization': `Bearer ${token}`}});
    //const dataUserLoginRes = userLoginRes.data[0];
    //setUserLogin(dataUserLoginRes);
  };

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-4">
          <li className="breadcrumb-item">
            Hi, {greetings}.{" "}
            {userLogin !== "" ? userLogin.vendor_name : "PT. Company"}
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {userLogin !== "" ? userLogin.user_id : "Username"}
          </li>
        </ol>
      </nav>
    </div>
  );
};

export default BreadCumb;
