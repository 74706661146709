import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import BootIcon from "bootstrap-icons/bootstrap-icons.svg";
import {
  getToken,
  logout,
  xhr
} from "../utils";
const localStorage = require("local-storage");

const Navbar = (props) => {
  const token = getToken();
  const [userLogin, setUserLogin] = useState("");

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    const userInfo = localStorage.get("user-info");
    if (userInfo) {
      setUserLogin(userInfo);
    } else {
      // get list inbound outstanding po
      const userLoginRes = await xhr.get("active", "/user-login", {
        headers: { Authorization: `Bearer ${token}` },
      });
      const dataUserLoginRes = userLoginRes.data[0];
      setUserLogin(dataUserLoginRes);
    }
  };

  const backToHome = (e) => {
    window.location.href = "/";
  };

  return (
    <nav className="navbar navbar-light bg-light">
      <div className="container-fluid">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasSidebar"
          aria-controls="offcanvasSidebar"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        {props.title !== "disable" ? (
          <div
            onClick={backToHome}
            className="text-center position-absolute start-50 translate-middle-x cursor-pointer"
          >
            <h3>OEM Management System</h3>
          </div>
        ) : (
          ""
        )}
        <div className="position-absolute end-0 me-5">
          <Link
            to="#"
            className="d-flex align-items-center text-black-50 text-decoration-none dropdown-toggle"
            id="dropdownUser"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <svg className="bi" width="32" height="32" fill="currentColor">
              <use xlinkHref={`${BootIcon}#person-circle`} />
            </svg>
            <strong className="ms-sm-2 me-sm-2" style={{ fontSize: "1rem" }}>
              {userLogin !== "" ? userLogin?.vendor_name || "" : "vendor"}
            </strong>
          </Link>
          <ul
            className="dropdown-menu dropdown-menu-dark text-small shadow"
            aria-labelledby="dropdownUser"
          >
            <li
              onClick={(e) => {
                logout();
              }}
            >
              <Link className="dropdown-item" to="#">
                Sign out
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
