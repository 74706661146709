import { HotTable } from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';
import React, { useEffect, useRef } from 'react';
import {getToken, xhr} from '../../utils';
import moment from 'moment';
import Swal from 'sweetalert2';

let tableData = []
const HandsontableMps = ({headers, columns, data, masterMaterials, filter, setLoading, generateTable, ...props}) => {
  registerAllModules();
  const token = getToken()

  let tableRef = useRef(null);

  let settings = {
    manualColumnResize: true,
    height: '80vh',
    fixedColumnsStart: 6,
    autoWrapRow: true,
    autoWrapCol: true,
    hiddenColumns: {
      columns: [0]
    },
    beforeRemoveRow:(index, amount, physicalRows, [source]) => {
      let deletedIds = [];
      physicalRows.forEach( elm => {
        const tableRow = tableData[elm];
        if(tableRow.id){
          deletedIds.push(tableRow.id)
        }
        const dataidx = data.indexOf(tableRow)
        tableData.splice(elm, 1)
        data.splice(dataidx, 1)
      });

      if(deletedIds.length) {
        deleteMps({id: deletedIds})
      }
      
      return true
    },
    afterChange: (changes, source) => {
      const hot = tableRef?.current?.hotInstance;
      changes?.forEach( async ([row, prop, oldValue, newValue]) => {
        const selectedRow = tableData[row];
        const dataIdx = data.indexOf(selectedRow);
        let confirmed = true;
        if(oldValue !== newValue){

          if(prop === 'material_number'){
            confirmed = false;  
            await Swal.fire({
              title: `Are you sure want to select ${newValue} ?`,
              text: "You won't be able to revert this!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes, confirm it!"
            }).then((result) => {
              confirmed = result.isConfirmed
              if(confirmed) {
                const material = masterMaterials.find(item => +item.material_number === +newValue)
                if(material){
                  tableData[row] = {
                    ...tableData[row],
                    description: material.description,
                    base_uom: material.base_uom,
                    active: 1,
                  }
                  hot.loadData(tableData)
                  hot.render()
                }
                console.log('material', material)
              }else{
                tableData[row] = {
                  ...tableData[row],
                  material_number: oldValue
                }
                hot.loadData(tableData)
                hot.render()
                return false;
              }
            });
  
          }
  
          const rowData = hot.getDataAtRow(row)
          const mpsKey = [
            'id', 
            'plant_id', 
            'material_number',
            'description',
            'base_uom',
            'version',
            'active',
          ];
          let mpsData = {};
          let mpsDetails = [];
          columns.forEach((item, index) => {
            if(mpsKey.includes(item.data)){
              mpsData[item.data] = rowData[index]
            }else{
              let detail = {
                datakey: item.data,
                value: rowData[index],
              }
              mpsDetails.push(detail)
            }
          })
          mpsData['sorting'] = row;
  
          const storeData = {
            data: mpsData,
            details: mpsDetails,
            startdate: moment(props.headerInput.from).format("DD.MM.YYYY"),
            enddate: moment(props.headerInput.to).format("DD.MM.YYYY"),
          }
          
          if(confirmed){
            setLoading(true);
            xhr.post('active', '/mps/create', storeData, {headers: {'Authorization': `Bearer ${token}`}})
            .then(res => {
              let newMps = {...res.data};
              setLoading(false)
              if(res?.data){
                if(res.details){
                  res.details.forEach(detail => {
                    let newDetail = {};
                    newDetail[detail.datakey] = detail.value
                    newMps = {
                      ...newMps, 
                      ...newDetail
                    }
                  });
                }
                tableData[row] = {...newMps}
                data[dataIdx] = { ...tableData[row] }
                //hot.loadData(tableData);
                generateTable()
              }
            })
            .catch(err => {
              Swal.fire({
                title: 'Error!',
                text: err.message || err,
                icon: 'error',
                confirmButtonText: 'Close'
              })
              setLoading(false)
            })
          }
        }
      });

    },
    // contextMenu: ['remove_row'],
    // contextMenu: true,
    // contextMenu: {
    //   items: {
    //     'remove_row': {},
    //     'separator': ContextMenu.SEPARATOR,
    //   }
    // },
    licenseKey: 'non-commercial-and-evaluation'
  };

  const deleteMps = async (reqParams = {}) => {
    if(reqParams.id){
      await xhr.post('active', '/mps/delete', reqParams, {headers: {'Authorization': `Bearer ${token}`}})
        .catch(err => {
          Swal.fire({
            title: 'Error!',
            text: err.message || err,
            icon: 'error',
            confirmButtonText: 'Close'
          })
        })
    }
  }

  useEffect(() => {
    tableData = [...data];
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const hot = tableRef?.current?.hotInstance;
    tableData = [...data];
    hot.loadData(tableData)
  }, [data]);

  // useEffect(() => {
  //   setDataMaterials(masterMaterials)
  // }, [masterMaterials]);

  useEffect(() => {
    const hot = tableRef?.current?.hotInstance;
    hot.updateSettings({
      ...settings,
      data: tableData,
      colHeaders: headers,
      columns: columns,
    })
    hot.render()
    // eslint-disable-next-line
  }, [headers, columns])

  // eslint-disable-next-line
  const addNewRow = () => {
    const hot = tableRef?.current?.hotInstance;
    hot.alter('insert_row_below', hot.countRows(), 1)
  }

  // Function to filter data
  useEffect( () => {
    const hot = tableRef?.current?.hotInstance;
    const filteredData = data.filter( row => {
      return (row.description.toString().toLowerCase()).includes(filter.toString().toLowerCase())
    });
    
    if(filter){
      tableData = filteredData;
    }else{
      tableData = [...data];
    }    
    hot.loadData(tableData);
    // eslint-disable-next-line
  }, [filter])

  return (
    <>
      <HotTable
        ref={tableRef}
        settings={{
          ...settings,
          columns: columns,
          colHeaders: headers,
        }}
        >
      </HotTable>
      {/* 
      <div className='d-flex p-4'>
        <button type="button" className="btn btn-primary" onClick={addNewRow}>add row</button>
      </div>
      */}
    </>
  );
}

export default HandsontableMps;
