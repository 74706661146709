/**
 * risyandi - 2021
 * utils for consume or fetch API using axios
 * 
 * example :
 * xhr.post('active', '/login', formdata); --> path only
 * xhr.post('noactive', 'https://../api/v1/..', formdata); --> full path only
 * 
 * using path only and baseurl from constant : baseurl = active
 * using full path only and without baseurl from constand : baseurl = noactive
 */

import axios from 'axios';
import {apiURL} from '../constant';
import { storage } from './localStorage';
import { logout } from './jwtToken';

const apiBaseUrl = process.env.NODE_ENV !== 'development' ? apiURL.prod : apiURL.dev;


console.log('REACT_APP_PROD_ENDPOINT' + process.env.REACT_APP_PROD_ENDPOINT);
console.log('REACT_APP_DEV_ENDPOINT' + process.env.REACT_APP_DEV_ENDPOINT);

function baseAxios(options, baseurl, pathname) {
    const headers = {
        ...options.headers,
    }
    const apiClient = axios.create({
        baseURL: `${baseurl !== 'active' ? pathname : apiBaseUrl+pathname}`,
        timeout: options.timeout || 30000,
        headers,
    });
    
    // Request Interceptor
    apiClient.interceptors.request.use(
        config => {
            const token = storage.get('token');
            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }
            return config;
        },
        error => {
            console.error('Request Error:', error);
            return Promise.reject(error);
        }
    );
  
    // Response Interceptor
    apiClient.interceptors.response.use(
        response => {
            return response;
        },
        async error => {
            console.error('Response Error:', error);
            if (error.response && error.response.status === 401) {
                logout()
            }
            return Promise.reject(error);
        }
    );

    return apiClient;
}

function executeRequest(method, baseurl, pathname, data, options = {}) {
    const body = method === 'get' || !data ? {} : {
        data
    }
    const reqObj = {
        url: `${baseurl !== 'active' ? pathname : apiBaseUrl+pathname}`,
        method,
        params: options.query,
        ...body,
    }
    const baseAxiosRequest = baseAxios(options, baseurl, pathname)
    const xhr = new Promise((resolve, reject) => {
        baseAxiosRequest
            .request(reqObj)
            .then(res => {
                resolve(res.data)
            })
            .catch(async (error) => {
                const res = error.response
                const status = res ? res.status : ''
                if (res &&
                    res.data &&
                    res.data.message) {
                    error = res.data
                }
                console.log('HTTP ERROR: ', `Message: ${error.message}, Code: ${status}`)
                reject(error)
            })
    })

    return xhr
}

export const xhr = {
    async get(baseurl, pathname, options) {
        return executeRequest('get', baseurl, pathname, null, options)
    },

    async post(baseurl, pathname, data, options) {
        return executeRequest('post', baseurl, pathname, data, options)
    },

    async put(baseurl, pathname, data, options) {
        return executeRequest('put', baseurl, pathname, data, options)
    },

    async delete(baseurl, pathname, data, options) {
        return executeRequest('delete', baseurl, pathname, data, options)
    },

    async all(promises) {
        return Promise.all(promises)
    },

    async spread(response) {
        let spread = axios.spread(response);
        return spread
    },

    setUrl(pathname) {
        return apiBaseUrl + pathname;
    }
}