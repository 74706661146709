import React, { useState, useEffect, useRef } from 'react'

import Select from 'react-select';
import {xhr} from '../../utils';
import qs from 'querystring';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import 'handsontable/dist/handsontable.full.css';

import '../../assets/css/plant.css';
import BootIcon from 'bootstrap-icons/bootstrap-icons.svg';

import 'react-day-picker/lib/style.css';
import {formatDate, parseDate} from 'react-day-picker/moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import Loader from '../../common/Loader';
import moment from 'moment';
import Swal from 'sweetalert2';
import HandsontableComponent from './HandsontableComponent';

const styleSelect = {
  input: (e) => ({
      width: 100
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 300, // Adjust z-index here
  }),
};

const DefaultDateFormat = "DD.MM.YYYY";
const MonthYearFormat = "MM.YYYY";
let currentData = [];
let currentOriginalData = [];
const RencanaProduksiHarian = (props) => {
  const userLogin = props.userLogin;
  const token = props.token;

  const initModal = {
    title: '',
    action: '',
  }

  let initHeaderInput = {
    order_month: '',
    from: '',
    to: '',
    plant_id: "",
  }

  let initHeaders = [
    'ID',
    'Start Date', //order month
    'Plant',
    'Order No',
    'Material',
    'Description',
    'Target Qty',
    'UOM',
    'Ver',
    'A', //Active
  ];

  let initColumns = [
    {
      type: 'text',
      readOnly: true,
      data: 'id',
      width: 100,
      className: 'htCenter'
    },
    {
      type: 'text',
      readOnly: true,
      data: 'start_date',
      width: 100,
      className: 'htCenter'
    },
    {
      type: 'text',
      readOnly: true,
      data: 'plant_id',
      width: 80,
      className: 'htCenter'
    },
    {
      type: 'dropdown',
      data: 'order_no',
      source: [],
      width: 120,
    },
    {
      type: 'text',
      readOnly: true,
      data: 'material_number',
      width: 100,
      className: 'htCenter'
    },
    {
      type: 'text',
      data: 'description',
      readOnly: true,
      width: 280,
      className: 'htLeft'
    },
    {
      type: 'numeric',
      readOnly: true,
      data: 'target_quantity',
      numericFormat: {
        pattern: '0,0.00',
        culture: 'en-US'
      },
      width: 80,
      className: 'htCenter'
    },
    {
      type: 'text',
      readOnly: true,
      data: 'base_uom',
      width: 80,
      className: 'htCenter'
    },
    {
      type: 'text',
      readOnly: true,
      data: 'version',
      width: 100,
      className: 'htCenter',
    },
    {
      type: 'checkbox',
      readOnly: true,
      data: 'active',
      width: 50,
      className: 'htCenter',
      checkedTemplate: 1,
      uncheckedTemplate: 0,
    },
  ]

  let tableRef = useRef(null);
  let selectedRph = null;

  let hotSettings = {
    fixedColumnsStart: 9,
    hiddenColumns: {
      columns: [0, 1],
    },
    beforeChange:(changes, source) => {
      changes?.forEach( ([row, prop, oldVal, newVal]) => {
        selectedRph = {...currentData[row]}
      })
    },
    afterChange: (changes, source) => {
      const hot = tableRef?.current?.hotInstance;
      changes?.forEach( async ([row, prop, oldValue, newValue]) => {
        let currentRow = {...currentData[row]};
        let previousRow = {...selectedRph};

        let confirmed = true;

        if(prop === 'order_no'){
          confirmed = false;
          await Swal.fire({
            title: `Are you sure want to select ${newValue} ?`,
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, confirm it!"
          }).then((result) => {
            confirmed = result.isConfirmed
            if(confirmed) {
              const po = productionOrders.find(item => item.order_num === newValue)
              if(po){
                currentRow = {
                  ...currentRow,
                  material_number: (po.material_no).replace(/^0+/, ''),
                  start_date: po.start_date,
                  description: po.material_desc,
                  base_uom: po.base_uom,
                  target_quantity: po.total_order_qty,
                  active: 1,
                }
                currentData[row] = {...currentRow};
                setHotData(currentData);
                hot.loadData(currentData)
              }
            } else {
              currentData[row] = {...previousRow };
              setHotData(currentData);
              hot.loadData(currentData)
              return false;
            }
          })
        }

        if(oldValue !== newValue && confirmed){
          // mapping and store data
          const rowData = {...currentRow}
          const rphKeys = initColumns.map(item => item.data )
          let rphData = {};
          let rphDetails = [];
          hotColumns.forEach((item, index) => {
            if(rphKeys.includes(item.data)){
              rphData[item.data] = rowData[item.data]
            }else{
              let detail = {
                datakey: item.data,
                value: currentRow[item.data] ?? null,
              }
              rphDetails.push(detail)
            }
          })

          rphData = {
            ...rphData,
            details: rphDetails,
            sorting: row,
            startdate: moment(headerInput.from).format("DD.MM.YYYY"),
            enddate: moment(headerInput.to).format("DD.MM.YYYY"),
          }
          
          setLoading(true);
          const reqRph = xhr.post('active', '/rph/create', rphData)
            .then(res => {
              if(res.data){
                setLoading(false)
                let newRph = {...res.data};
                if(res.details){
                  res.details.forEach(detail => {
                    let newDetail = {};
                    newDetail[detail.datakey] = detail.value
                    newRph = {
                      ...newRph, 
                      ...newDetail
                    }
                  });
                  delete newRph['details']
                }
                currentData[row] = { ...newRph };
                //currentOriginalData[originalIdx] = { ...newRph };
                setHotData(currentData);
                hot.loadData(currentData)
                hot.render()
              }
            })
            .finally( () => {
              setLoading(false)
            })          

            reqRph.catch(err => {
              Swal.fire({
                title: 'Error!',
                text: err.message || err,
                icon: 'error',
                confirmButtonText: 'Close'
              })
              setLoading(false)
              currentData[row] = { ...previousRow };
              //currentOriginalData[originalIdx] = { ...previousRow };
              setHotData(currentData);
              hot.loadData(currentData)
              hot.render()
            })
        }
      });
    },
  };

  const [headerInput, setHeaderInput] = useState(initHeaderInput);
  const [loading, setLoading] = useState(false);
  const [listPlant, setListPlant] = useState([]);
  // eslint-disable-next-line
  const [searchFilter, setSearchFilter] = useState('');
  // eslint-disable-next-line
  const [productionOrders, setProductionOrders] = useState([]);
  const [inputFile, setInputFile] = useState(null)
  
  // Hands on table init
  const [hotHeaders, sethotHeaders] = useState(initHeaders);
  const [hotColumns, setHotColumns] = useState(initColumns);
  const [hotData, setHotData] = useState([]);

  // modal
  const [show, setShow] = useState(false);
  const [modal, setModal] = useState(initModal);
  const closeModal = () => setShow(false);
  const openModal = () => setShow(true);

  const getRph = async (request) => {
    let result = {};
    const mpsRes = await xhr.post('active', `/rph/getAll`,
      request,
    {
      headers: {'Authorization': `Bearer ${token}`}
    });
    
    if(mpsRes.data) {
      result = mpsRes.data;
    }
    return result;
  }

  const validateFormHeader = async () => {
    const startDate = moment(headerInput.from);
    let endDate = moment(headerInput.to);
    let isValid = formHeaderValidation();
    let msg = startDate >= endDate ? 'Start date should be lower than end date' : ( !headerInput.order_month || !headerInput.plant_id || !headerInput.from || headerInput.to ? 'Field cannot be empty!' : '' );

    if(!isValid){
      await Swal.fire(
        'Error',
        msg,
        'error'
      )
    }
    return isValid;
  }

  const formHeaderValidation = () => {
    const startDate = moment(headerInput.from);
    let endDate = moment(headerInput.to);
    return startDate < endDate && (headerInput.order_month && headerInput.plant_id && headerInput.from && headerInput.to);
  } 

  const getPlant = async () => {
    let result = [];    
    if(userLogin?.company_id){
      const user_plant = userLogin?.plant_id;
      const reqQuery = qs.stringify({ plant_id: user_plant })
      const plantRes = await xhr.get('active', `/plant?${reqQuery}`, {
        headers: {'Authorization': `Bearer ${token}`},
      });
      if(plantRes.data) {
        result = plantRes.data.map(item => {
          return {
            ...item,
            value: item.plant_id,
            label: `${item.plant_id} - ${item.plant_desc}`,
          }
        })
      }
      return result;
    }
    return []
  }

  // Function to filter data
  useEffect( () => {
    const hot = tableRef?.current?.hotInstance;
    const filteredData = currentOriginalData.filter( row => {
      return (row.description.toString().toLowerCase()).includes(searchFilter.toString().toLowerCase())
    });
    
    if(searchFilter){
      currentData = filteredData;
      setHotData(currentData)
    }else{
      currentData = [...currentOriginalData];
      setHotData(currentData)
    }
    hot.loadData(currentData);

    // eslint-disable-next-line
  }, [searchFilter])

  useEffect( () => {
    getPlant().then(data => {
      setListPlant(data)
    })    
    // eslint-disable-next-line
  }, [userLogin]);

  useEffect( () => {
    const hot = tableRef?.current?.hotInstance;
    if(hot){
      currentData = [];
      setHotData(currentData)
    }
  }, []);

  const generateTable = async (e = null) => {
    if(e) {
      e.preventDefault();
    }
    const isValid = await validateFormHeader();
    if(isValid) {
      setLoading(true)
      try {

        let orderMonth = moment(headerInput.order_month);
        const startDate = moment(headerInput.from);
        let fromDate = moment(headerInput.from);
        let endDate = moment(headerInput.to);
        let plant_id = headerInput.plant_id;

        var duration = moment.duration(endDate.diff(startDate));
        var days = duration.asDays();

        let newHeaders = [];
        let newDefaultColumns = [];
        let newColumns = [];
        let newData = [];

        const reqQuery = qs.stringify({
          start_month: orderMonth.format('M'),
          start_year: orderMonth.format('YYYY'),
          plant_id: plant_id,
        })

        const poRes = await xhr.get('active', `/production-order?${reqQuery}`, {
          headers: {'Authorization': `Bearer ${token}`},
        });
        
        if(poRes.data){

          const PoData = poRes.data;
          setProductionOrders(PoData)
          const PoIds = PoData.map(item => item.order_num)

          // re-init column with options values;
          newDefaultColumns = [...initColumns]

          // set columns options and values
          const indexPlant = newDefaultColumns.findIndex(x => x.data ==="plant_id");
          const indexPO = newDefaultColumns.findIndex(x => x.data ==="order_no");
          let plantCol = newDefaultColumns[indexPlant];
          let poCol = newDefaultColumns[indexPO];
          plantCol = {
            ...plantCol,
            value: plant_id
          };
          poCol = {
            ...poCol,
            source: [...PoIds]
          };
          
          newDefaultColumns[indexPlant] = plantCol
          newDefaultColumns[indexPO] = poCol
          
          // generate column headers date and newcolumns dates 
          for (let i = 0; i <= days; i++) {
            newHeaders = [
              ...newHeaders,
              fromDate.format(DefaultDateFormat)
            ]
            newColumns = [
              ...newColumns,
              {
                type: 'numeric',
                data: fromDate.format(DefaultDateFormat),
                width: 100,
                className: 'htLeft'
              }
            ]
    
            // add 1 days
            fromDate.add(1, 'days');
          }
          // combine default and new hot init
          newHeaders = [ ...initHeaders, ...newHeaders]
          newColumns = [ ...newDefaultColumns, ...newColumns];
          
          // generate new data with empty value
          for (let i = 0; i < PoIds.length || 0; i++) {
            let newRow = {};
            newColumns.forEach( (col, i) => {
              let newCol = {};
              newCol[col.data] = col?.value || ''
              newRow = {
                ...newRow,
                ...newCol
              }
            })
            if(newRow) {
              newData = [
                ...newData,
                newRow
              ]
            }
          }

          // set Value
          await getRph({
            month: orderMonth.format('M'),
            year: orderMonth.format('YYYY'),
            from: startDate.format(DefaultDateFormat),
            to: endDate.format(DefaultDateFormat),
            plant_id: headerInput.plant_id,
            with: ['details'],
          }).then( data =>{
            const newRphData = [];
            let newDataTemplate = {};
            newColumns.forEach( (col, i) => {
              newDataTemplate[col.data] = col?.value || '';
            })
            if(data){
              data.forEach((item, idx) => {
                let newItem = { ...newDataTemplate, ...item };
                if(item?.details){
                  (item.details).forEach(child => {
                    newItem[child.datakey] = child.value ?? ''
                  })
                }
                delete newItem['details']
                newRphData.push(newItem)
              });
            }

            const numberOfElementsToRemove = newRphData.length;
            const newArray = [...newData];
            newArray.length = Math.max(newArray.length - numberOfElementsToRemove, 0);
            
            const currentRphData = [
              ...newRphData,
              ...newArray
            ];

            currentData = [...currentRphData];
            currentOriginalData = [...currentRphData ]
            sethotHeaders([...newHeaders])
            setHotColumns([...newColumns])
            setHotData(currentData)
    
          })
          
        }else{
          setProductionOrders([])
        }

      } catch (error) {
        
      } finally {
        setLoading(false)
      }
      
    }
  }

  const downloadExcel = async (e) => {
    const isValid = await validateFormHeader();

    if(isValid){ 
      setLoading(true);
      let orderMonth = moment(headerInput.order_month);
      const startDate = moment(headerInput.from);
      let endDate = moment(headerInput.to);

      const reqQuery = qs.stringify({
        plant_id: headerInput.plant_id,
        month: orderMonth.format('M'),
        month_name: orderMonth.format('MMM'),
        year: orderMonth.format('YYYY'),
        from: startDate.format(DefaultDateFormat),
        to: endDate.format(DefaultDateFormat),
      })

      const url = xhr.setUrl(
        `/export-excel/planning-rph?${reqQuery}`
      )
      window.open(url, '_blank');
    }
    setLoading(false);
    return;
  }

  const uploadExcel = async () => {
    try {
      if(typeof inputFile === 'object'){
        setLoading(true)
        const formData = new FormData();
        formData.append('excel', inputFile);

        const rphExcel = await xhr.post(
            'active', 
            `/rph/upload`,
            formData,
            {
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
              }
            }
          ).catch(err => {
            throw new Error(err.message)
          })
        
        if(rphExcel.data) {
          if(rphExcel.data?.error){
            throw new Error(rphExcel.data?.message)
          }else{
            
            Swal.fire(
              'Success',
              'Successfully uploaded',
              'success'
            )
            closeModal()
            
          }
          // re-generate table if form valid
          if(headerInput.plant_id && headerInput.from && headerInput.to && headerInput.order_month) generateTable()
          // if empty header input => get uploaded RPH 
          if(!formHeaderValidation()) {
            // Upload Excel file
            // generate table according to the uploaded file;
            const startDate = moment(rphExcel.data.from, DefaultDateFormat);
            let fromDate = moment(rphExcel.data.from, DefaultDateFormat);
            let endDate = moment(rphExcel.data.to, DefaultDateFormat);

            var duration = moment.duration(endDate.diff(startDate));
            var days = duration.asDays();

            let newHeaders = [];
            let newDefaultColumns = [ ...initColumns ];

            newDefaultColumns[3] = {
              data: 'order_no',
              type: 'text',
              readOnly: true,
              width: 120,
            };

            let newColumns = [];
            let newData = [];

            // generate column headers date and newcolumns dates 
            for (let i = 0; i <= days; i++) {
              newHeaders = [
                ...newHeaders,
                fromDate.format(DefaultDateFormat)
              ]
              newColumns = [
                ...newColumns,
                {
                  type: 'numeric',
                  data: fromDate.format(DefaultDateFormat),
                  width: 100,
                  className: 'htLeft'
                }
              ]
      
              // add 1 days
              fromDate.add(1, 'days');
            }
            // combine default and new hot init
            newHeaders = [ ...initHeaders, ...newHeaders]
            newColumns = [ ...newDefaultColumns, ...newColumns];
            
            const rphIds = rphExcel.data.data.map(item => item.id)
            await getRph({
              in: {
                id: rphIds
              },
              from: rphExcel.data.from,
              to: rphExcel.data.to,
              with: ['details'],
            }).then( async data => {
              const newRphData = [];
              let newDataTemplate = {};
              newColumns.forEach( (col, i) => {
                newDataTemplate[col.data] = col?.value || '';
              })
              if(data){
                data.forEach((item, idx) => {
                  let newItem = { ...newDataTemplate, ...item };
                  if(item?.details){
                    (item.details).forEach(child => {
                      newItem[child.datakey] = child.value ?? ''
                    })
                  }
                  delete newItem['details']
                  newRphData.push(newItem)
                });
              }

              const numberOfElementsToRemove = newRphData.length;
              const newArray = [...newData];
              newArray.length = Math.max(newArray.length - numberOfElementsToRemove, 0);
              
              const currentRphData = [
                ...newRphData,
                ...newArray
              ];

              currentData = [...currentRphData];
              currentOriginalData = [...currentRphData ];
              sethotHeaders([...newHeaders]);
              setHotColumns([...newColumns]);
              setHotData(currentData);
            })
          }
        }

      }else{
        throw new Error("error format")
      }
    } catch (error) {
      Swal.fire(
        'Error',
        error.message,
        'error'
      )
    } finally {
      setLoading(false)
    }
    
  }

  return (
    <>
      <Loader loading={loading}/>
      <div className="container-fluid bg-soft-gray h-100 my-3">
        <div className="container-content mx-3 py-4">
          <div className="card pb-4">

            <div className='card-title text-center bg-soft-tosca pt-2'>
              <h5 className='text-white'><strong>Rencana Produksi Harian (RPH)</strong></h5>
            </div>

            {/* form planning horizon */}
            <div className="row m-0">
              <div className='col-sm-12 col-md-12 col-xl-6 wrapper-goodreceive'>
                {/* form rph */}
                <form
                  onSubmit={generateTable}
                  >
                  <div className="card m-xl-4 p-3">
                    <strong className='mb-3'><u>Planning Horizon</u></strong>
                    
                    {/* Month and Year */}
                    <div className="mb-3 row m-0">
                      {/* Order month */}
                      <div className='col-sm-6 col-md-6 d-flex p-0'>
                        <label htmlFor="inputActualDate" className="col-sm-3 col-form-label">Order Month <span className="text-danger">*</span></label>
                        <div className="col-sm-8">
                          <div className="DayPicker-input-group form-floating mb-1">
                            <DayPickerInput 
                              format={MonthYearFormat}
                              formatDate={formatDate}
                              parseDate={parseDate}
                              placeholder={MonthYearFormat}
                              dayPickerProps={{
                                todayButton: 'Go To Today',
                              }}
                              keepFocus={false} 
                              id="inputActualDate"
                              onDayChange = {
                                (day) => setHeaderInput({
                                  ...headerInput,
                                  order_month: formatDate(day)
                                })
                              }
                              
                            />
                            <span className="DayPicker-input-group-text bg-white">
                            <svg className="bi" width="20" height="20" fill="currentColor">
                            <use xlinkHref={`${BootIcon}#calendar3`}/>
                            </svg>
                          </span>
                          </div>
                        </div>
                      </div>

                      <div className='col-sm-6 col-md-6 d-flex p-0'>
                        
                      </div>
                    </div>

                    {/* start and to date */}
                    <div className="mb-3 row m-0">
                      {/* from date */}
                      <div className='col-sm-6 col-md-6 d-flex p-0'>
                        <label htmlFor="inputActualDate" className="col-sm-3 col-form-label">From <span className="text-danger">*</span></label>
                        <div className="col-sm-8">
                          <div className="DayPicker-input-group form-floating mb-1">
                            <DayPickerInput 
                              format={DefaultDateFormat}
                              formatDate={formatDate}
                              parseDate={parseDate}
                              placeholder={DefaultDateFormat}
                              dayPickerProps={{
                                todayButton: 'Go To Today',
                              }}
                              keepFocus={false} 
                              id="inputActualDate"
                              onDayChange = {
                                (day) => setHeaderInput({
                                  ...headerInput,
                                  from: formatDate(day)
                                })
                              }
                              
                            />
                            <span className="DayPicker-input-group-text bg-white">
                            <svg className="bi" width="20" height="20" fill="currentColor">
                            <use xlinkHref={`${BootIcon}#calendar3`}/>
                            </svg>
                          </span>
                          </div>
                        </div>
                      </div>
                      {/* to date */}
                      <div className='col-sm-6 col-md-6 d-flex p-0'>
                        <label htmlFor="inputActualDate" className="col-sm-3 px-3 col-form-label">To <span className="text-danger">*</span></label>
                        <div className="col-sm-8">
                          <div className="DayPicker-input-group form-floating mb-1">
                            <DayPickerInput 
                              format={DefaultDateFormat}
                              formatDate={formatDate}
                              parseDate={parseDate}
                              placeholder={DefaultDateFormat}
                              dayPickerProps={{
                                todayButton: 'Go To Today',
                              }}
                              keepFocus={false} 
                              id="inputActualDate"
                              onDayChange = {
                                (day) => setHeaderInput({
                                  ...headerInput,
                                  to: formatDate(day)
                                })
                              }
                            />
                            <span className="DayPicker-input-group-text bg-white">
                            <svg className="bi" width="20" height="20" fill="currentColor">
                            <use xlinkHref={`${BootIcon}#calendar3`}/>
                            </svg>
                          </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Plant */}
                    <div className="mb-3 row m-0">
                      <div className='col-sm-6 col-md-6 d-flex p-0'>
                        <label htmlFor="input-memo" className="col-sm-3 col-form-label">Plant</label>
                        {/* select dropdown table */}
                        <Select 
                          options={listPlant} 
                          isClearable={true}
                          styles={styleSelect}
                          className="w-100"
                          menuPosition="absolute"
                          placeholder="Choose Plant"
                          id="plant_id"
                          name="plant_id"
                          
                          onChange = {
                            (event) => {
                              setHeaderInput({
                                ...headerInput,
                                plant_id: event?.value || '' 
                              })
                            }
                          }
                          />
                      </div>
                      <div className='col-sm-6 col-md-6 d-flex justify-content-end p-0'>
                        <button type='submit' className='btn btn-sm btn-primary mb-3 py-1 px-4'>Go</button>
                      </div>
                    </div>
                    
                  </div>
                </form>
              </div>
            </div>
            
            {/* filter and buttons */}
            <div className='row mx-xl-4 mb-sm-3'>
              {/* buttons */}
              <div className='col-8 p-4'>
                <button className='btn btn-sm btn-primary' onClick={downloadExcel} >Download</button>
                <button className='btn btn-sm btn-primary mx-3' onClick={() => {
                  openModal()
                  setModal({
                    ...modal,
                    title: 'Upload',
                    action: 1
                  })
                }}>Upload</button>
              </div>
              {/* Filter */}
              <div className='col-4 p-4'>
                <input 
                  className='form-control' 
                  placeholder='Search...'
                  onChange = {
                    (e) => {
                      setSearchFilter(e?.value || '' )
                    }
                  }
                  onKeyUp={
                    (e) => {
                      if(e.keyCode === 13){
                        setSearchFilter(e?.target.value || '' )
                      }
                    }
                  }
                  />
              </div>
            </div>
            
            {/* Handsontable */}
            <div className="row">
              <div className='col-12'>
                <div className='table-responsive'>
                  <HandsontableComponent
                    headers={hotHeaders} 
                    columns={hotColumns}
                    data={hotData}
                    headerInput={headerInput}
                    setLoading={setLoading}
                    setting={hotSettings}
                    tableRef={tableRef}
                  />
                </div>
              </div>
            </div>

            {/* Modal */}
            <Modal 
              show={show} 
              onHide={closeModal}
              backdrop="static"
              keyboard={false}
              size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              >
              <Modal.Header closeButton>
                <Modal.Title>{modal.title}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {modal.action === 1 ? (
                  <>
                    {/* Upload excel */}
                    <div className="mb-3">
                      <label htmlFor="input-upload" className="form-label">Select file</label>
                      <input className="form-control form-control" id="input-upload" type="file" accept=".xls, .xlsx" onChange={(e) => {
                        if(e.target.files){
                          setInputFile(e.target.files[0])
                        }
                      } }/>
                    </div>
                  </>
                ) : modal.action === 2 ? ( 
                  <>
                    Method changed
                  </>
                ) : (
                  <div>No Action!</div>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>
                  Close
                </Button>
                { modal.action === 1 ? (
                  <Button variant="primary" onClick={uploadExcel}>
                    Save
                  </Button>
                ) : (
                  <></>
                )}
              </Modal.Footer>
            </Modal>

          </div>
        </div>
      </div>
    </>
  )
}

export default RencanaProduksiHarian