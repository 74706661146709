import React from "react";
import PlanningPage from "./pages/Planning";

const HomePage = React.lazy(() => import("./pages/Home"));
const LoginPage = React.lazy(() => import("./pages/Login"));
const SSOPage = React.lazy(() => import("./pages/SSO"));
const BackgroundJobsPage = React.lazy(() => import("./pages/BackgroundJobs"));
const ProfilePage = React.lazy(() => import("./pages/Profile"));
const InboundPage = React.lazy(() => import("./pages/Inbound"));
const OutboundPage = React.lazy(() => import("./pages/Outbound"));
const OutboundFGPage = React.lazy(() => import("./pages/OutboundFG"));
const ProductionPage = React.lazy(() => import("./pages/Production"));
const ProductionGIPage = React.lazy(() => import("./pages/GI"));
const ProductionConfirmGIPage = React.lazy(() => import("./pages/ConfirmGI"));
const VendorPage = React.lazy(() => import("./pages/MasterData/Vendor"));
const UserPage = React.lazy(() => import("./pages/MasterData/User"));
const CompanyPage = React.lazy(() => import("./pages/MasterData/Company"));
const ConfigurationEmailPage = React.lazy(() =>
  import("./pages/Configuration/Email")
);
const ConfigurationMappingPage = React.lazy(() =>
  import("./pages/Configuration/Mapping")
);
const ConfigurationPlantPage = React.lazy(() =>
  import("./pages/Configuration/Plant")
);

const routes = [
  {
    path: "/",
    restricted: true,
    exact: true,
    name: "Homepage",
    component: HomePage,
    typeLayout: "full-layout",
  },
  {
    path: "/login",
    restricted: false,
    exact: true,
    name: "Login",
    component: LoginPage,
    typeLayout: "full-layout",
  },
  {
    path: "/sso",
    restricted: false,
    exact: true,
    name: "SSO",
    component: SSOPage,
    typeLayout: "full-layout",
  },
  {
    path: "/background-jobs",
    restricted: true,
    exact: true,
    name: "Background Jobs",
    component: BackgroundJobsPage,
    typeLayout: "full-layout",
  },
  {
    path: "/profile",
    restricted: true,
    exact: true,
    name: "Profile",
    component: ProfilePage,
    typeLayout: "full-layout",
  },
  {
    path: "/profile/create",
    restricted: true,
    exact: true,
    name: "Create Sub Profile",
    component: ProfilePage,
    typeLayout: "full-layout",
  },
  {
    path: "/profile/edit",
    restricted: true,
    exact: true,
    name: "Edit Sub Profile",
    component: ProfilePage,
    typeLayout: "full-layout",
  },
  {
    path: "/inbound",
    restricted: true,
    exact: true,
    name: "Outstanding PO",
    component: InboundPage,
    typeLayout: "full-layout",
  },
  {
    path: "/inbound/outstanding-po",
    restricted: true,
    exact: true,
    name: "Outstanding PO",
    component: InboundPage,
    typeLayout: "full-layout",
  },
  {
    path: "/inbound/good-receive",
    restricted: true,
    exact: true,
    name: "Good Receive",
    component: InboundPage,
    typeLayout: "full-layout",
  },
  {
    path: "/inbound/report-receive",
    restricted: true,
    exact: true,
    name: "Report Receive",
    component: InboundPage,
    typeLayout: "full-layout",
  },
  {
    path: "/inbound/report-rmpm/pdf",
    restricted: true,
    exact: true,
    name: "Report Receive",
    component: InboundPage,
    typeLayout: "full-layout",
  },
  {
    path: "/outbound",
    restricted: true,
    exact: true,
    name: "Memo Return",
    component: OutboundPage,
    typeLayout: "full-layout",
  },
  {
    path: "/outbound/memo-return",
    restricted: true,
    exact: true,
    name: "Memo Return",
    component: OutboundPage,
    typeLayout: "full-layout",
  },
  {
    path: "/outbound/create-memo-return",
    restricted: true,
    exact: true,
    name: "Create Memo Return",
    component: OutboundPage,
    typeLayout: "full-layout",
  },
  {
    path: "/outbound/edit-memo-return",
    restricted: true,
    exact: true,
    name: "Edit Memo Return",
    component: OutboundPage,
    typeLayout: "full-layout",
  },
  {
    path: "/outbound/confirmation-memo-return",
    restricted: true,
    exact: true,
    name: "Confirmation Memo Return",
    component: OutboundPage,
    typeLayout: "full-layout",
  },
  {
    path: "/outbound/outstanding-po-return",
    restricted: true,
    exact: true,
    name: "Outstanding PO Return",
    component: OutboundPage,
    typeLayout: "full-layout",
  },
  {
    path: "/outbound/outstanding-po-return-post",
    restricted: true,
    exact: true,
    name: "Outstanding PO Return",
    component: OutboundPage,
    typeLayout: "full-layout",
  },
  {
    path: "/outbound/list-confirmation-memo-return",
    restricted: true,
    exact: true,
    name: "List Confirmation Memo Return",
    component: OutboundPage,
    typeLayout: "full-layout",
  },
  {
    path: "/outbound/report-return-receive",
    restricted: true,
    exact: true,
    name: "Report Return Receive",
    component: OutboundPage,
    typeLayout: "full-layout",
  },
  {
    path: "/outbound-fg",
    restricted: true,
    exact: true,
    name: "Report PO Finish Goods",
    component: OutboundFGPage,
    typeLayout: "full-layout",
  },
  {
    path: "/outbound-fg/report-po-fg",
    restricted: true,
    exact: true,
    name: "Report PO Finish Goods",
    component: OutboundFGPage,
    typeLayout: "full-layout",
  },
  {
    path: "/outbound-fg/report-prod-order",
    restricted: true,
    exact: true,
    name: "Report Production Order",
    component: OutboundFGPage,
    typeLayout: "full-layout",
  },
  {
    path: "/outbound-fg/report-gr",
    restricted: true,
    exact: true,
    name: "Report GR at DC Nabati Group - Finish Goods",
    component: OutboundFGPage,
    typeLayout: "full-layout",
  },
  {
    path: "/outbound-fg/report-gr/pdf",
    restricted: true,
    exact: true,
    name: "Report PDF GR",
    component: OutboundFGPage,
    typeLayout: "full-layout",
  },
  {
    path: "/production",
    restricted: true,
    exact: true,
    name: "GR Production Order",
    component: ProductionPage,
    typeLayout: "full-layout",
  },
  {
    path: "/production/gr-production-order",
    restricted: true,
    exact: true,
    name: "GR Production Order",
    component: ProductionPage,
    typeLayout: "full-layout",
  },
  {
    path: "/production/gr-production-receipts",
    restricted: true,
    exact: true,
    name: "GR Production Receipts",
    component: ProductionPage,
    typeLayout: "full-layout",
  },
  // Added 09-19-2024 by rizamasyhadi@gmail.com
  {
    path: "/production/gr-transaction",
    restricted: true,
    exact: true,
    name: "GR Transaction List",
    component: ProductionPage,
    typeLayout: "full-layout",
  },
  // end Added 09-19-2024 by rizamasyhadi@gmail.com
  {
    path: "/production/report-stock",
    restricted: true,
    exact: true,
    name: "Report Stock RM/PM/SFG",
    component: ProductionPage,
    typeLayout: "full-layout",
  },
  {
    path: "/master-data/vendor",
    restricted: true,
    exact: true,
    name: "Master Data Vendor",
    component: VendorPage,
    typeLayout: "full-layout",
  },
  {
    path: "/master-data/vendor/create",
    restricted: true,
    exact: true,
    name: "Create Master Data Vendor",
    component: VendorPage,
    typeLayout: "full-layout",
  },
  {
    path: "/master-data/vendor/edit",
    restricted: true,
    exact: true,
    name: "Edit Master Data Vendor",
    component: VendorPage,
    typeLayout: "full-layout",
  },
  {
    path: "/master-data/company",
    restricted: true,
    exact: true,
    name: "Master Data Company",
    component: CompanyPage,
    typeLayout: "full-layout",
  },
  {
    path: "/master-data/company/create",
    restricted: true,
    exact: true,
    name: "Create Master Data Company",
    component: CompanyPage,
    typeLayout: "full-layout",
  },
  {
    path: "/master-data/company/edit",
    restricted: true,
    exact: true,
    name: "Edit Master Data Company",
    component: CompanyPage,
    typeLayout: "full-layout",
  },
  {
    path: "/master-data/user",
    restricted: true,
    exact: true,
    name: "Master Data User",
    component: UserPage,
    typeLayout: "full-layout",
  },
  {
    path: "/master-data/user/create",
    restricted: true,
    exact: true,
    name: "Create Master Data User",
    component: UserPage,
    typeLayout: "full-layout",
  },
  {
    path: "/master-data/user/edit",
    restricted: true,
    exact: true,
    name: "Edit Master Data User",
    component: UserPage,
    typeLayout: "full-layout",
  },
  {
    path: "/configuration/email",
    restricted: true,
    exact: true,
    name: "Configuration Email",
    component: ConfigurationEmailPage,
    typeLayout: "full-layout",
  },
  {
    path: "/configuration/email/create",
    restricted: true,
    exact: true,
    name: "Create Configuration Email",
    component: ConfigurationEmailPage,
    typeLayout: "full-layout",
  },
  {
    path: "/configuration/email/edit",
    restricted: true,
    exact: true,
    name: "Edit Configuration Email",
    component: ConfigurationEmailPage,
    typeLayout: "full-layout",
  },
  {
    path: "/configuration/mapping",
    restricted: true,
    exact: true,
    name: "Configuration Mapping",
    component: ConfigurationMappingPage,
    typeLayout: "full-layout",
  },
  {
    path: "/configuration/mapping/create",
    restricted: true,
    exact: true,
    name: "Create Configuration Mapping",
    component: ConfigurationMappingPage,
    typeLayout: "full-layout",
  },
  {
    path: "/configuration/mapping/edit",
    restricted: true,
    exact: true,
    name: "Edit Configuration Mapping",
    component: ConfigurationMappingPage,
    typeLayout: "full-layout",
  },
  {
    path: "/configuration/plant",
    restricted: true,
    exact: true,
    name: "Configuration Plant",
    component: ConfigurationPlantPage,
    typeLayout: "full-layout",
  },
  {
    path: "/configuration/plant/create",
    restricted: true,
    exact: true,
    name: "Create Configuration Plant",
    component: ConfigurationPlantPage,
    typeLayout: "full-layout",
  },
  {
    path: "/configuration/plant/edit",
    restricted: true,
    exact: true,
    name: "Edit Configuration Plant",
    component: ConfigurationPlantPage,
    typeLayout: "full-layout",
  },
  /**
   * New Routing
   * GI PRO
   * 08-June-22
   */
  {
    path: "/production/gi-request",
    restricted: true,
    exact: true,
    name: "GI Production Order Request",
    component: ProductionGIPage,
    typeLayout: "full-layout",
  },
  {
    path: "/production/gi-request/create",
    restricted: true,
    exact: true,
    name: "GI Production Order Request - Create",
    component: ProductionGIPage,
    typeLayout: "full-layout",
  },
  {
    path: "/production/gi-confirm",
    restricted: true,
    exact: true,
    name: "GI Production Order Confirmation",
    component: ProductionConfirmGIPage,
    typeLayout: "full-layout",
  },
  {
    path: "/production/gi-confirm/action",
    restricted: true,
    exact: true,
    name: "GI Production Order Confirmation - Action",
    component: ProductionConfirmGIPage,
    typeLayout: "full-layout",
  },
  {
    path: "/production/gi-report",
    restricted: true,
    exact: true,
    name: "Report GI PrO Request",
    component: ProductionGIPage,
    typeLayout: "full-layout",
  },
  {
    path: "/production/gi-report-emp",
    restricted: true,
    exact: true,
    name: "Report GI PrO Request",
    component: ProductionGIPage,
    typeLayout: "full-layout",
  },
  /**
   * New Routing
   * Gi Transaction
   * 07-September-24
   */
  {
    path: "/production/gi-transaction",
    restricted: true,
    exact: true,
    name: "GI Request Transaction",
    component: ProductionGIPage,
    typeLayout: "full-layout",
  },
  {
    path: "/production/gi-cost-center",
    restricted: true,
    exact: true,
    name: "GI to Cost Center",
    component: ProductionGIPage,
    typeLayout: "full-layout",
  },
  {
    path: "/production/gi-reservation",
    restricted: true,
    exact: true,
    name: "GI to Reservation",
    component: ProductionGIPage,
    typeLayout: "full-layout",
  },
  {
    path: "/production/gi-reservation-trx",
    restricted: true,
    exact: true,
    name: "GI Reservation list",
    component: ProductionGIPage,
    typeLayout: "full-layout",
  },
  /**
   * New Routing
   * Plant
   * 08-August-24
   */
  {
    path: "/planning/mps",
    restricted: true,
    exact: true,
    name: "Master Production Schedule (MPS)",
    component: PlanningPage,
    typeLayout: "full-layout",
  },
  {
    path: "/planning/rph",
    restricted: true,
    exact: true,
    name: "Rencana Produksi Harian (RPH)",
    component: PlanningPage,
    typeLayout: "full-layout",
  },
  {
    path: "/planning/report-achievement",
    restricted: true,
    exact: true,
    name: "Report Monitoring Achievement",
    component: PlanningPage,
    typeLayout: "full-layout",
  },
];

export default routes;
