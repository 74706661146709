import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { img } from '../assets/img/index'
import BootIcon from 'bootstrap-icons/bootstrap-icons.svg'
import { getToken, xhr } from '../utils'
const localStorage = require('local-storage')

const Sidebar = props => {
  const token = getToken()
  const [userRolesMenu, setUserRolesMenu] = useState([])
  // eslint-disable-next-line
  const [userInfo, setUserInfo] = useState({})

  const menuUsers = []
  userRolesMenu.map(item => {
    return menuUsers.push(item.menu_id)
  })

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line
  }, [])

  const fetchData = async () => {
    const userInfo = localStorage.get('user-info')
    const userRole = localStorage.get('user-role')

    if (userInfo && userRole) {
      setUserInfo(userInfo)
      setUserRolesMenu(userRole)
    } else {
      // get list inbound outstanding po
      await xhr
        .get('active', '/user-login', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then(res => {
          let rolesVendor = res.data[0].roles_id
          localStorage.set('user-info', res.data[0])
          setUserInfo(res.data[0])
          xhr.get('active', `/user-roles-menu/${rolesVendor}`).then(res => {
            const data = res.data
            setUserRolesMenu(data)
            localStorage.set('user-role', data)
          })
        })
        .catch(err => {
          console.log(err)
        })
    }
  }

  const backToHome = e => {
    window.location.href = '/'
  }

  const mainMenu = data => {
    switch (data) {
      case 1:
        return (
          // profile
          <li className='nav-item' key={data}>
            <Link
              to='/profile'
              className='nav-link text-white'
              aria-current='page'
            >
              Profile
            </Link>
          </li>
        )
      case 2:
        return (
          // background data
          <li className='nav-item' key={data}>
            <Link to='/background-jobs' className='nav-link text-white'>
              Background Data
            </Link>
          </li>
        )
      case 13:
        return (
          // report stock rmpm
          <li className='nav-item' key={data}>
            <Link
              to='/production/report-stock'
              className='nav-link text-white'
              aria-current='page'
            >
              Report Stock RMPMFG
            </Link>
          </li>
        )
      default:
        break
    }
  }

  const subMenuInbound = data => {
    switch (data) {
      case 3:
        return (
          <li key={data}>
            <Link
              to='/inbound/outstanding-po'
              className='nav-link text-white ps-5'
            >
              Outstanding PO
            </Link>
          </li>
        )
      case 4:
        return (
          <li key={data}>
            <Link
              to='/inbound/report-receive'
              className='nav-link text-white ps-5'
            >
              Report Goods Receive
            </Link>
          </li>
        )
      default:
        break
    }
  }

  const headMenuInbound = data => {
    return (
      <div
        className='nav-item-tab'
        data-bs-toggle='collapse'
        data-bs-target='#inbound-collapse'
        aria-expanded='false'
      >
        <Link
          to='#'
          className='nav-link text-white'
          onClick={e => e.preventDefault()}
        >
          Inbound RMPM
          <div className='icon-chev-right'>
            <svg className='bi' width='15' height='15' fill='currentColor'>
              <use xlinkHref={`${BootIcon}#chevron-right`} />
            </svg>
          </div>
        </Link>
      </div>
    )
  }

  const subMenuOutbound = data => {
    switch (data) {
      case 5:
        return (
          <li key={data}>
            <Link
              to='/outbound/memo-return'
              className='nav-link text-white ps-5'
            >
              Memo Return
            </Link>
          </li>
        )
      case 6:
        return (
          <li key={data}>
            <Link
              to='/outbound/list-confirmation-memo-return'
              className='nav-link text-white ps-5'
            >
              Memo Return Confirm.
            </Link>
          </li>
        )
      case 7:
        return (
          <li key={data}>
            <Link
              to='/outbound/outstanding-po-return'
              className='nav-link text-white ps-5'
            >
              Outstanding PO Return
            </Link>
          </li>
        )
      case 8:
        return (
          <li key={data}>
            <Link
              to='/outbound/report-return-receive'
              className='nav-link text-white ps-5'
            >
              Report Return Goods Receive
            </Link>
          </li>
        )
      default:
        break
    }
  }

  const headMenuOutbound = () => {
    return (
      <div
        className='nav-item-tab'
        data-bs-toggle='collapse'
        data-bs-target='#outbound-collapse'
        aria-expanded='false'
      >
        <Link
          to='#'
          className='nav-link text-white'
          onClick={e => e.preventDefault()}
        >
          Outbound RMPM
          <div className='icon-chev-right'>
            <svg className='bi' width='15' height='15' fill='currentColor'>
              <use xlinkHref={`${BootIcon}#chevron-right`} />
            </svg>
          </div>
        </Link>
      </div>
    )
  }

  const subMenuOutboundFg = data => {
    switch (data) {
      case 9:
        return (
          <li key={data}>
            <Link
              to='/outbound-fg/report-po-fg'
              className='nav-link text-white ps-5'
            >
              Report PO Finish Goods
            </Link>
          </li>
        )
      case 10:
        return (
          <li key={data}>
            <Link
              to='/outbound-fg/report-prod-order'
              className='nav-link text-white ps-5'
            >
              Report Production Order
            </Link>
          </li>
        )
      case 11:
        return (
          <li key={data}>
            <Link
              to='/outbound-fg/report-gr'
              className='nav-link text-white ps-5'
            >
              Report GR at DC Nabati - FG
            </Link>
          </li>
        )
      default:
        break
    }
  }

  const headMenuOutboundFg = () => {
    return (
      <div
        className='nav-item-tab'
        data-bs-toggle='collapse'
        data-bs-target='#outbound-fg-collapse'
        aria-expanded='false'
      >
        <Link
          to='#'
          className='nav-link text-white'
          onClick={e => e.preventDefault()}
        >
          Outbound Finish Goods
          <div className='icon-chev-right'>
            <svg className='bi' width='15' height='15' fill='currentColor'>
              <use xlinkHref={`${BootIcon}#chevron-right`} />
            </svg>
          </div>
        </Link>
      </div>
    )
  }

  const subMenuProduction = data => {
    switch (data) {
      case 12:
        return (
          <li key={data}>
            <Link
              to='/production/gr-production-order'
              className='nav-link text-white ps-5'
            >
              GR Production Order
            </Link>
          </li>
        )
      case 20:
        return (
          <li key={data}>
            <Link
              to='/production/gi-request'
              className='nav-link text-white ps-5'
            >
              GI Production Order
            </Link>
          </li>
        )
      case 21:
        // if (userInfo?.vendor_type === 'Nabati') {
        //   return (
        //     <li key={data}>
        //       <Link
        //         to='/production/gi-confirm'
        //         className='nav-link text-white ps-5'
        //       >
        //         GI Production Order Confirm
        //       </Link>
        //     </li>
        //   )
        // }
        break
      case 22:
        // if (userInfo?.vendor_type === 'Vendor') {
        //   return (
        //     <li key={data}>
        //       <Link
        //         to='/production/gi-report'
        //         className='nav-link text-white ps-5'
        //       >
        //         Report GI PrO Request
        //       </Link>
        //     </li>
        //   )
        // }
        break
      case 23:
        // if (userInfo?.vendor_type === 'Nabati') {
        //   return (
        //     <li key={data}>
        //       <Link
        //         to='/production/gi-report-emp'
        //         className='nav-link text-white ps-5'
        //       >
        //         Report GI PrO Request
        //       </Link>
        //     </li>
        //   )
        // }
        break
      case 24:
        return (
          <li key={data}>
            <Link
              to='/production/gi-cost-center'
              className='nav-link text-white ps-5'
            >
              GI to Cost Center
            </Link>
          </li>
        )
      case 25:
        return (
          <li key={data}>
            <Link
              to='/production/gi-reservation'
              className='nav-link text-white ps-5'
            >
              GI to Reservation
            </Link>
          </li>
        )
      default:
        break
    }
  }

  const headMenuProduction = () => {
    return (
      <div
        className='nav-item-tab'
        data-bs-toggle='collapse'
        data-bs-target='#production-collapse'
        aria-expanded='false'
      >
        <Link
          to='#'
          className='nav-link text-white'
          onClick={e => e.preventDefault()}
        >
          Production
          <div className='icon-chev-right'>
            <svg className='bi' width='15' height='15' fill='currentColor'>
              <use xlinkHref={`${BootIcon}#chevron-right`} />
            </svg>
          </div>
        </Link>
      </div>
    )
  }

  const subMenuMasterData = data => {
    switch (data) {
      case 14:
        return (
          <li key={data}>
            <Link to='/master-data/vendor' className='nav-link text-white ps-5'>
              Vendor
            </Link>
          </li>
        )
      case 15:
        return (
          <li key={data}>
            <Link
              to='/master-data/company'
              className='nav-link text-white ps-5'
            >
              Company
            </Link>
          </li>
        )
      case 19:
        return (
          <li key={data}>
            <Link to='/master-data/user' className='nav-link text-white ps-5'>
              User
            </Link>
          </li>
        )
      default:
        break
    }
  }

  const headMenuMasterData = () => {
    return (
      <div
        className='nav-item-tab'
        data-bs-toggle='collapse'
        data-bs-target='#masterdata-collapse'
        aria-expanded='false'
      >
        <Link
          to='#'
          className='nav-link text-white'
          onClick={e => e.preventDefault()}
        >
          Master Data
          <div className='icon-chev-right'>
            <svg className='bi' width='15' height='15' fill='currentColor'>
              <use xlinkHref={`${BootIcon}#chevron-right`} />
            </svg>
          </div>
        </Link>
      </div>
    )
  }

  const subMenuConfiguration = data => {
    switch (data) {
      case 16:
        return (
          <li key={data}>
            <Link
              to='/configuration/email'
              className='nav-link text-white ps-5'
            >
              Email
            </Link>
          </li>
        )
      case 17:
        return (
          <li key={data}>
            <Link
              to='/configuration/mapping'
              className='nav-link text-white ps-5'
            >
              Mapping
            </Link>
          </li>
        )
      case 18:
        return (
          <li key={data}>
            <Link
              to='/configuration/plant'
              className='nav-link text-white ps-5'
            >
              Plant
            </Link>
          </li>
        )
      default:
        break
    }
  }

  const headMenuConfiguration = () => {
    return (
      <div
        className='nav-item-tab'
        data-bs-toggle='collapse'
        data-bs-target='#configuration-collapse'
        aria-expanded='false'
      >
        <Link
          to='#'
          className='nav-link text-white'
          onClick={e => e.preventDefault()}
        >
          Configuration
          <div className='icon-chev-right'>
            <svg className='bi' width='15' height='15' fill='currentColor'>
              <use xlinkHref={`${BootIcon}#chevron-right`} />
            </svg>
          </div>
        </Link>
      </div>
    )
  }

  return (
    <div
      className='offcanvas'
      tabIndex='-1'
      id='offcanvasSidebar'
      aria-labelledby='offcanvasSidebar'
    >
      <main>
        <div
          className='d-flex flex-column flex-shrink-0 p-3 text-white bg-soft-tosca'
          style={{ width: '280px' }}
        >
          <div className='text-center cursor-pointer' onClick={backToHome}>
            <img
              src={img.logoNabati}
              className='nabati-logo'
              alt='nabati logo'
            />
          </div>
          <br />
          <ul className='nav nav-pills flex-column mb-auto'>
            {/* profile - background data - report stock rmpm */}
            {userRolesMenu.map(data => {
              let menuId = data.menu_id
              return mainMenu(menuId)
            })}

            {/* inbound rmpm */}
            {userRolesMenu.length !== 0 ? (
              userRolesMenu[0].roles_id === 'vendor-plant' ? (
                <li className='nav-item'>
                  <div
                    className='nav-item-tab'
                    data-bs-toggle='collapse'
                    data-bs-target='#inbound-collapse'
                    aria-expanded='false'
                  >
                    <Link
                      to='#'
                      className='nav-link text-white'
                      onClick={e => e.preventDefault()}
                    >
                      Inbound RMPM
                      <div className='icon-chev-right'>
                        <svg
                          className='bi'
                          width='15'
                          height='15'
                          fill='currentColor'
                        >
                          <use xlinkHref={`${BootIcon}#chevron-right`} />
                        </svg>
                      </div>
                    </Link>
                  </div>

                  <div className='collapse' id='inbound-collapse'>
                    <ul className='btn-toggle-nav list-unstyled fw-normal pb-1'>
                      {userRolesMenu.map(data => {
                        let menuId = data.menu_id
                        return subMenuInbound(menuId)
                      })}
                    </ul>
                  </div>
                </li>
              ) : (
                ''
              )
            ) : (
              ''
            )}

            {userRolesMenu.length !== 0 &&
              userRolesMenu[0].roles_id.includes('sub-user') !== false && (
                <li className='nav-item'>
                  {menuUsers.includes(3 || 4) && headMenuInbound()}

                  <div className='collapse' id='inbound-collapse'>
                    <ul className='btn-toggle-nav list-unstyled fw-normal pb-1'>
                      {userRolesMenu.map(data => {
                        let menuId = data.menu_id
                        return subMenuInbound(menuId)
                      })}
                    </ul>
                  </div>
                </li>
              )}

            {/* outbound rmpm */}
            {userRolesMenu.length !== 0 ? (
              userRolesMenu[0].roles_id === 'vendor-plant' ||
              userRolesMenu[0].roles_id === 'admin' ? (
                <li className='nav-item'>
                  <div
                    className='nav-item-tab'
                    data-bs-toggle='collapse'
                    data-bs-target='#outbound-collapse'
                    aria-expanded='false'
                  >
                    <Link
                      to='#'
                      className='nav-link text-white'
                      onClick={e => e.preventDefault()}
                    >
                      Outbound RMPM
                      <div className='icon-chev-right'>
                        <svg
                          className='bi'
                          width='15'
                          height='15'
                          fill='currentColor'
                        >
                          <use xlinkHref={`${BootIcon}#chevron-right`} />
                        </svg>
                      </div>
                    </Link>
                  </div>

                  <div className='collapse' id='outbound-collapse'>
                    <ul className='btn-toggle-nav list-unstyled fw-normal pb-1'>
                      {userRolesMenu.map(data => {
                        let menuId = data.menu_id
                        return subMenuOutbound(menuId)
                      })}
                    </ul>
                  </div>
                </li>
              ) : (
                ''
              )
            ) : (
              ''
            )}

            {userRolesMenu.length !== 0 &&
              userRolesMenu[0].roles_id.includes('sub-user') && (
                <li className='nav-item'>
                  {menuUsers.includes(5 || 6 || 7 || 8) && headMenuOutbound()}

                  <div className='collapse' id='outbound-collapse'>
                    <ul className='btn-toggle-nav list-unstyled fw-normal pb-1'>
                      {userRolesMenu.map(data => {
                        let menuId = data.menu_id
                        return subMenuOutbound(menuId)
                      })}
                    </ul>
                  </div>
                </li>
              )}

            {/* outbound finish goods */}
            {userRolesMenu.length !== 0 ? (
              userRolesMenu[0].roles_id === 'vendor-no-plant' ||
              userRolesMenu[0].roles_id === 'admin' ||
              userRolesMenu[0].roles_id === 'vendor-plant' ? (
                <li className='nav-item'>
                  <div
                    className='nav-item-tab'
                    data-bs-toggle='collapse'
                    data-bs-target='#outbound-fg-collapse'
                    aria-expanded='false'
                  >
                    <Link
                      to='#'
                      className='nav-link text-white'
                      onClick={e => e.preventDefault()}
                    >
                      Outbound Finish Goods
                      <div className='icon-chev-right'>
                        <svg
                          className='bi'
                          width='15'
                          height='15'
                          fill='currentColor'
                        >
                          <use xlinkHref={`${BootIcon}#chevron-right`} />
                        </svg>
                      </div>
                    </Link>
                  </div>

                  <div className='collapse' id='outbound-fg-collapse'>
                    <ul className='btn-toggle-nav list-unstyled fw-normal pb-1'>
                      {userRolesMenu.map(data => {
                        let menuId = data.menu_id
                        return subMenuOutboundFg(menuId)
                      })}
                    </ul>
                  </div>
                </li>
              ) : (
                ''
              )
            ) : (
              ''
            )}

            {userRolesMenu.length !== 0 &&
              userRolesMenu[0].roles_id.includes('sub-user') !== false && (
                <li className='nav-item'>
                  {menuUsers.includes(9 || 10 || 11) && headMenuOutboundFg()}

                  <div className='collapse' id='outbound-fg-collapse'>
                    <ul className='btn-toggle-nav list-unstyled fw-normal pb-1'>
                      {userRolesMenu.map(data => {
                        let menuId = data.menu_id
                        return subMenuOutboundFg(menuId)
                      })}
                    </ul>
                  </div>
                </li>
              )}

            {/* production */}
            {userRolesMenu.length !== 0 ? (
              userRolesMenu[0].roles_id === 'vendor-plant' ? (
                <li className='nav-item'>
                  <div
                    className='nav-item-tab'
                    data-bs-toggle='collapse'
                    data-bs-target='#production-collapse'
                    aria-expanded='false'
                  >
                    <Link
                      to='#'
                      className='nav-link text-white'
                      onClick={e => e.preventDefault()}
                    >
                      Production
                      <div className='icon-chev-right'>
                        <svg
                          className='bi'
                          width='15'
                          height='15'
                          fill='currentColor'
                        >
                          <use xlinkHref={`${BootIcon}#chevron-right`} />
                        </svg>
                      </div>
                    </Link>
                  </div>
                  <div className='collapse' id='production-collapse'>
                    <ul className='btn-toggle-nav list-unstyled fw-normal pb-1'>
                      {userRolesMenu.map(data => {
                        let menuId = data.menu_id
                        return subMenuProduction(menuId)
                      })}
                    </ul>
                  </div>
                </li>
              ) : (
                ''
              )
            ) : (
              ''
            )}

            {userRolesMenu.length !== 0 &&
              userRolesMenu[0].roles_id.includes('sub-user') !== false && (
                <li className='nav-item'>
                  {menuUsers.includes(12) && headMenuProduction()}

                  <div className='collapse' id='production-collapse'>
                    <ul className='btn-toggle-nav list-unstyled fw-normal pb-1'>
                      {userRolesMenu.map(data => {
                        let menuId = data.menu_id
                        return subMenuProduction(menuId)
                      })}
                    </ul>
                  </div>
                </li>
              )}

            {/* master data */}
            {userRolesMenu.length !== 0 ? (
              userRolesMenu[0].roles_id === 'admin' ? (
                <li className='nav-item'>
                  <div
                    className='nav-item-tab'
                    data-bs-toggle='collapse'
                    data-bs-target='#masterdata-collapse'
                    aria-expanded='false'
                  >
                    <Link
                      to='#'
                      className='nav-link text-white'
                      onClick={e => e.preventDefault()}
                    >
                      Master Data
                      <div className='icon-chev-right'>
                        <svg
                          className='bi'
                          width='15'
                          height='15'
                          fill='currentColor'
                        >
                          <use xlinkHref={`${BootIcon}#chevron-right`} />
                        </svg>
                      </div>
                    </Link>
                  </div>

                  <div className='collapse' id='masterdata-collapse'>
                    <ul className='btn-toggle-nav list-unstyled fw-normal pb-1'>
                      {userRolesMenu.map(data => {
                        let menuId = data.menu_id
                        return subMenuMasterData(menuId)
                      })}
                    </ul>
                  </div>
                </li>
              ) : (
                ''
              )
            ) : (
              ''
            )}

            {userRolesMenu.length !== 0 &&
              userRolesMenu[0].roles_id.includes('sub-user') !== false && (
                <li className='nav-item'>
                  {menuUsers.includes(14 || 15 || 19) && headMenuMasterData()}

                  <div className='collapse' id='masterdata-collapse'>
                    <ul className='btn-toggle-nav list-unstyled fw-normal pb-1'>
                      {userRolesMenu.map(data => {
                        let menuId = data.menu_id
                        return subMenuMasterData(menuId)
                      })}
                    </ul>
                  </div>
                </li>
              )}

            {/* production admin */}
            {userRolesMenu.length !== 0 ? (
              userRolesMenu[0].roles_id === 'admin' ? (
                <li className='nav-item'>
                  <div
                    className='nav-item-tab'
                    data-bs-toggle='collapse'
                    data-bs-target='#production-collapse'
                    aria-expanded='false'
                  >
                    <Link
                      to='#'
                      className='nav-link text-white'
                      onClick={e => e.preventDefault()}
                    >
                      Production
                      <div className='icon-chev-right'>
                        <svg
                          className='bi'
                          width='15'
                          height='15'
                          fill='currentColor'
                        >
                          <use xlinkHref={`${BootIcon}#chevron-right`} />
                        </svg>
                      </div>
                    </Link>
                  </div>
                  {/* <div className='collapse' id='production-collapse'>
                    <ul className='btn-toggle-nav list-unstyled fw-normal pb-1'>
                      <li>
                        <Link
                          to='/production/gi-confirm'
                          className='nav-link text-white ps-5'
                        >
                          GI Production Order Confirm
                        </Link>
                      </li>
                    </ul>
                    <ul className="btn-toggle-nav list-unstyled fw-normal pb-1">
                      <li>
                        <Link
                          to="/production/gi-report-emp"
                          className="nav-link text-white ps-5"
                        >
                          Report GI PrO Request
                        </Link>
                      </li>
                    </ul>
                  </div> */}
                </li>
              ) : (
                ''
              )
            ) : (
              ''
            )}

            {/* planning admin */}
            {userRolesMenu.length !== 0 ? (
              userRolesMenu[0].roles_id === 'admin' || userRolesMenu[0].roles_id.includes('vendor-plant') ? (
                <li className='nav-item'>
                  <div
                    className='nav-item-tab'
                    data-bs-toggle='collapse'
                    data-bs-target='#planning-collapse'
                    aria-expanded='false'
                  >
                    <Link
                      to='#'
                      className='nav-link text-white'
                      onClick={e => e.preventDefault()}
                    >
                      Planning
                      <div className='icon-chev-right'>
                        <svg
                          className='bi'
                          width='15'
                          height='15'
                          fill='currentColor'
                        >
                          <use xlinkHref={`${BootIcon}#chevron-right`} />
                        </svg>
                      </div>
                    </Link>
                  </div>

                  {/* Planning 09-10-2024 */}
                  <div className='collapse' id='planning-collapse'>
                    <ul className='btn-toggle-nav list-unstyled fw-normal pb-1'>
                      { userRolesMenu[0].roles_id === 'admin' ? (
                        <li>
                          <Link
                            to='/planning/mps'
                            className='nav-link text-white ps-5'
                          >
                            Master Production Schedule (MPS)
                          </Link>
                        </li>
                      ) : userRolesMenu[0].roles_id === 'vendor-plant' ? (
                        <>
                          <li>
                            <Link
                              to="/planning/rph"
                              className="nav-link text-white ps-5"
                            >
                              Rencana Produksi Harian (RPH)
                            </Link>
                          </li>
                        </>
                      ) : (
                        <></>
                      )}
                      <li>
                        <Link
                          to="/planning/report-achievement"
                          className="nav-link text-white ps-5"
                        >
                          Report Monitoring Achievement
                        </Link>
                      </li>
                    </ul>
                    
                  </div>
                </li>
              ) : (
                ''
              )
            ) : (
              ''
            )}

            {/* configuration */}
            {userRolesMenu.length !== 0 ? (
              userRolesMenu[0].roles_id === 'admin' ? (
                <li className='nav-item'>
                  <div
                    className='nav-item-tab'
                    data-bs-toggle='collapse'
                    data-bs-target='#configuration-collapse'
                    aria-expanded='false'
                  >
                    <Link
                      to='#'
                      className='nav-link text-white'
                      onClick={e => e.preventDefault()}
                    >
                      Configuration
                      <div className='icon-chev-right'>
                        <svg
                          className='bi'
                          width='15'
                          height='15'
                          fill='currentColor'
                        >
                          <use xlinkHref={`${BootIcon}#chevron-right`} />
                        </svg>
                      </div>
                    </Link>
                  </div>

                  <div className='collapse' id='configuration-collapse'>
                    <ul className='btn-toggle-nav list-unstyled fw-normal pb-1'>
                      {userRolesMenu.map(data => {
                        let menuId = data.menu_id
                        return subMenuConfiguration(menuId)
                      })}
                    </ul>
                  </div>
                </li>
              ) : (
                ''
              )
            ) : (
              ''
            )}

            {userRolesMenu.length !== 0 &&
              userRolesMenu[0].roles_id.includes('sub-user') !== false && (
                <li className='nav-item'>
                  {menuUsers.includes(16 || 17 || 18) &&
                    headMenuConfiguration()}

                  <div className='collapse' id='configuration-collapse'>
                    <ul className='btn-toggle-nav list-unstyled fw-normal pb-1'>
                      {userRolesMenu.map(data => {
                        let menuId = data.menu_id
                        return subMenuConfiguration(menuId)
                      })}
                    </ul>
                  </div>
                </li>
              )}

            {/* menu super-admin */}
            {userRolesMenu.length !== 0 ? (
              userRolesMenu[0].roles_id === 'super-admin' ? (
                <>
                  <li className='nav-item'>
                    <Link
                      to='/profile'
                      className='nav-link text-white'
                      aria-current='page'
                    >
                      Profile
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link to='/background-jobs' className='nav-link text-white'>
                      Background Data
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <div
                      className='nav-item-tab'
                      data-bs-toggle='collapse'
                      data-bs-target='#inbound-collapse'
                      aria-expanded='false'
                    >
                      <Link
                        to='#'
                        className='nav-link text-white'
                        onClick={e => e.preventDefault()}
                      >
                        Inbound RMPM
                        <div className='icon-chev-right'>
                          <svg
                            className='bi'
                            width='15'
                            height='15'
                            fill='currentColor'
                          >
                            <use xlinkHref={`${BootIcon}#chevron-right`} />
                          </svg>
                        </div>
                      </Link>
                    </div>

                    <div className='collapse' id='inbound-collapse'>
                      <ul className='btn-toggle-nav list-unstyled fw-normal pb-1'>
                        <li>
                          <Link
                            to='/inbound/outstanding-po'
                            className='nav-link text-white ps-5'
                          >
                            Outstanding PO
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/inbound/report-receive'
                            className='nav-link text-white ps-5'
                          >
                            Report Goods Receive
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className='nav-item'>
                    <div
                      className='nav-item-tab'
                      data-bs-toggle='collapse'
                      data-bs-target='#outbound-collapse'
                      aria-expanded='false'
                    >
                      <Link
                        to='#'
                        className='nav-link text-white'
                        onClick={e => e.preventDefault()}
                      >
                        Outbound RMPM
                        <div className='icon-chev-right'>
                          <svg
                            className='bi'
                            width='15'
                            height='15'
                            fill='currentColor'
                          >
                            <use xlinkHref={`${BootIcon}#chevron-right`} />
                          </svg>
                        </div>
                      </Link>
                    </div>

                    <div className='collapse' id='outbound-collapse'>
                      <ul className='btn-toggle-nav list-unstyled fw-normal pb-1'>
                        <li>
                          <Link
                            to='/outbound/memo-return'
                            className='nav-link text-white ps-5'
                          >
                            Memo Return
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/outbound/list-confirmation-memo-return'
                            className='nav-link text-white ps-5'
                          >
                            Memo Return Confirm.
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/outbound/outstanding-po-return'
                            className='nav-link text-white ps-5'
                          >
                            Outstanding PO Return
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/outbound/report-return-receive'
                            className='nav-link text-white ps-5'
                          >
                            Report Return Goods Receive
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className='nav-item'>
                    <div
                      className='nav-item-tab'
                      data-bs-toggle='collapse'
                      data-bs-target='#outbound-fg-collapse'
                      aria-expanded='false'
                    >
                      <Link
                        to='#'
                        className='nav-link text-white'
                        onClick={e => e.preventDefault()}
                      >
                        Outbound Finish Goods
                        <div className='icon-chev-right'>
                          <svg
                            className='bi'
                            width='15'
                            height='15'
                            fill='currentColor'
                          >
                            <use xlinkHref={`${BootIcon}#chevron-right`} />
                          </svg>
                        </div>
                      </Link>
                    </div>

                    <div className='collapse' id='outbound-fg-collapse'>
                      <ul className='btn-toggle-nav list-unstyled fw-normal pb-1'>
                        <li>
                          <Link
                            to='/outbound-fg/report-po-fg'
                            className='nav-link text-white ps-5'
                          >
                            Report PO Finish Goods
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/outbound-fg/report-prod-order'
                            className='nav-link text-white ps-5'
                          >
                            Report Production Order
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/outbound-fg/report-gr'
                            className='nav-link text-white ps-5'
                          >
                            Report GR at DC Nabati - FG
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className='nav-item'>
                    <div
                      className='nav-item-tab'
                      data-bs-toggle='collapse'
                      data-bs-target='#production-collapse'
                      aria-expanded='false'
                    >
                      <Link
                        to='#'
                        className='nav-link text-white'
                        onClick={e => e.preventDefault()}
                      >
                        Production
                        <div className='icon-chev-right'>
                          <svg
                            className='bi'
                            width='15'
                            height='15'
                            fill='currentColor'
                          >
                            <use xlinkHref={`${BootIcon}#chevron-right`} />
                          </svg>
                        </div>
                      </Link>
                    </div>
                    <div className='collapse' id='production-collapse'>
                      <ul className='btn-toggle-nav list-unstyled fw-normal pb-1'>
                        <li>
                          <Link
                            to='/production/gr-production-order'
                            className='nav-link text-white ps-5'
                          >
                            GR Production Order
                          </Link>
                        </li>
                      </ul>
                      <ul className='btn-toggle-nav list-unstyled fw-normal pb-1'>
                        <li>
                          <Link
                            to='/production/gi-request'
                            className='nav-link text-white ps-5'
                          >
                            GI Production Order
                          </Link>
                        </li>
                      </ul>
                      <ul className='btn-toggle-nav list-unstyled fw-normal pb-1'>
                        <li>
                          <Link
                            to='/production/gi-cost-center'
                            className='nav-link text-white ps-5'
                          >
                            GI to Cost Center
                          </Link>
                        </li>
                      </ul>
                      <ul className='btn-toggle-nav list-unstyled fw-normal pb-1'>
                        <li>
                          <Link
                            to='/production/gi-reservation'
                            className='nav-link text-white ps-5'
                          >
                            GI to Reservation
                          </Link>
                        </li>
                      </ul>
                      {/* 
                      <ul className='btn-toggle-nav list-unstyled fw-normal pb-1'>
                        <li>
                          <Link
                            to='/production/gi-confirm'
                            className='nav-link text-white ps-5'
                          >
                            GI Production Order Confirm
                          </Link>
                        </li>
                      </ul> 
                      <ul className="btn-toggle-nav list-unstyled fw-normal pb-1">
                        <li>
                          <Link
                            to="/production/gi-report-emp"
                            className="nav-link text-white ps-5"
                          >
                            Report GI Pro Request
                          </Link>
                        </li>
                      </ul>
                      */}
                    </div>
                  </li>
                  <li className='nav-item'>
                    <Link
                      to='/production/report-stock'
                      className='nav-link text-white'
                      aria-current='page'
                    >
                      Report Stock RMPMFG
                    </Link>
                  </li>
                  {/* PLANNING : MPS / RPH / REPORT MONITOR */}
                  <li className='nav-item'>
                    <div
                      className='nav-item-tab'
                      data-bs-toggle='collapse'
                      data-bs-target='#planning-collapse'
                      aria-expanded='false'
                    >
                      <Link
                        to='#'
                        className='nav-link text-white'
                        onClick={e => e.preventDefault()}
                      >
                        Planning
                        <div className='icon-chev-right'>
                          <svg
                            className='bi'
                            width='15'
                            height='15'
                            fill='currentColor'
                          >
                            <use xlinkHref={`${BootIcon}#chevron-right`} />
                          </svg>
                        </div>
                      </Link>
                    </div>
                    <div className='collapse' id='planning-collapse'>
                      <ul className='btn-toggle-nav list-unstyled fw-normal pb-1'>
                        <li>
                          <Link
                            to='/planning/mps'
                            className='nav-link text-white ps-5'
                          >
                            Master Production Schedule (MPS)
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/planning/rph"
                            className="nav-link text-white ps-5"
                          >
                            Rencana Produksi Harian (RPH)
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/planning/report-achievement"
                            className="nav-link text-white ps-5"
                          >
                            Report Monitoring Achievement
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className='nav-item'>
                    <div
                      className='nav-item-tab'
                      data-bs-toggle='collapse'
                      data-bs-target='#masterdata-collapse'
                      aria-expanded='false'
                    >
                      <Link
                        to='#'
                        className='nav-link text-white'
                        onClick={e => e.preventDefault()}
                      >
                        Master Data
                        <div className='icon-chev-right'>
                          <svg
                            className='bi'
                            width='15'
                            height='15'
                            fill='currentColor'
                          >
                            <use xlinkHref={`${BootIcon}#chevron-right`} />
                          </svg>
                        </div>
                      </Link>
                    </div>

                    <div className='collapse' id='masterdata-collapse'>
                      <ul className='btn-toggle-nav list-unstyled fw-normal pb-1'>
                        <li>
                          <Link
                            to='/master-data/vendor'
                            className='nav-link text-white ps-5'
                          >
                            Vendor
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className='collapse' id='masterdata-collapse'>
                      <ul className='btn-toggle-nav list-unstyled fw-normal pb-1'>
                        <li>
                          <Link
                            to='/master-data/company'
                            className='nav-link text-white ps-5'
                          >
                            Company
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className='collapse' id='masterdata-collapse'>
                      <ul className='btn-toggle-nav list-unstyled fw-normal pb-1'>
                        <li>
                          <Link
                            to='/master-data/user'
                            className='nav-link text-white ps-5'
                          >
                            User
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className='nav-item'>
                    <div
                      className='nav-item-tab'
                      data-bs-toggle='collapse'
                      data-bs-target='#configuration-collapse'
                      aria-expanded='false'
                    >
                      <Link
                        to='#'
                        className='nav-link text-white'
                        onClick={e => e.preventDefault()}
                      >
                        Configuration
                        <div className='icon-chev-right'>
                          <svg
                            className='bi'
                            width='15'
                            height='15'
                            fill='currentColor'
                          >
                            <use xlinkHref={`${BootIcon}#chevron-right`} />
                          </svg>
                        </div>
                      </Link>
                    </div>
                    <div className='collapse' id='configuration-collapse'>
                      <ul className='btn-toggle-nav list-unstyled fw-normal pb-1'>
                        <li>
                          <Link
                            to='/configuration/email'
                            className='nav-link text-white ps-5'
                          >
                            Email
                          </Link>
                        </li>
                      </ul>
                      <ul className='btn-toggle-nav list-unstyled fw-normal pb-1'>
                        <li>
                          <Link
                            to='/configuration/mapping'
                            className='nav-link text-white ps-5'
                          >
                            Mapping
                          </Link>
                        </li>
                      </ul>
                      <ul className='btn-toggle-nav list-unstyled fw-normal pb-1'>
                        <li>
                          <Link
                            to='/configuration/plant'
                            className='nav-link text-white ps-5'
                          >
                            Plant
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  
                </>
              ) : (
                ''
              )
            ) : (
              ''
            )}
          </ul>
        </div>
      </main>
    </div>
  )
}

export default Sidebar

// Notes of number menus:
// A. Profile (1)
// B. background data (2)
// C. inbound RMPM  > outstanding PO (3)
// 		            > report Goods Receive (4)
// D. outbound RMPM > memo return (5)
// 		            > memo return confirm (6)
// 		            > outstanding po return (7)
// 		            > report return goods receive (8)
// E. outbound FG	> report PO finish goods (9)
// 		            > report production order (10)
// 		            > report GR at DC nabati (11)
// F. production	> GR production order (12)
//                > GI production order request (20) #New Menu 08-June-22 vendor-type: Vendor
//                > GI production order confirm (21) #New Menu 08-June-22 vendor-type: Nabati
//                > GI production order report (22) #New Menu 08-June-22 vendor-type: Vendor
//                > GI production order report (23) #New Menu 08-June-22 vendor-type: Nabati
// G. report stock rmpm (13)
// H. master data 	> vendor (14)
// 		            > company (15)
// 		            > users SSO (19)
// I. configuration > email (16)
// 		            > mapping (17)
// 		            > plant (18)
