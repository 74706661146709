import { HotTable } from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';
import React, { useEffect, useState } from 'react';

const HandsontableComponent = ({headers = [], columns = [], data = [], setting = {}, tableRef, mergeCell = [],search = ''}) => {
  registerAllModules();

  const initSetting = {
    manualColumnResize: true,
    height: '70vh',
    fixedColumnsStart: 9,
    autoWrapRow: true,
    autoWrapCol: true,
    hiddenColumns: {
      columns: [0]
    },
    licenseKey: 'non-commercial-and-evaluation', 
    ...setting
  };

  // const [tableData, setTableData] = useState([]);
  const [hotSettings, setHotSettings] = useState(initSetting);

  useEffect(() => {
    const hot = tableRef?.current?.hotInstance;
    hot.loadData(data)
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    const hot = tableRef?.current?.hotInstance;
    if (hot) {
      const newSettings = {
        ...initSetting,
        ...setting,
        colHeaders: headers,
        columns: columns,
      }
      setHotSettings(newSettings);
      hot.updateSettings(newSettings)
      hot.render()
    }
    // eslint-disable-next-line
  }, [headers, columns])

  return (
    <>
      <HotTable
        ref={tableRef}
        settings={hotSettings}
        mergeCells={mergeCell}
        >
      </HotTable>
    </>
  );
}

export default HandsontableComponent;
