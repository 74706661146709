import React, { useState, useEffect, useRef } from 'react'

import Select from 'react-select';
import {xhr} from '../../utils';
import 'handsontable/dist/handsontable.full.css';

import '../../assets/css/plant.css';
import BootIcon from 'bootstrap-icons/bootstrap-icons.svg';
import {formatDate, parseDate} from 'react-day-picker/moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import Loader from '../../common/Loader';
import qs from 'querystring';
import moment from 'moment';
import Swal from 'sweetalert2';
import HandsontableComponent from './HandsontableComponent';
const localStorage = require('local-storage');

const styleSelect = {
  input: (e) => ({
      width: 100
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 300, // Adjust z-index here
  }),
};

const DefaultDateFormat = "DD.MM.YYYY";
const MonthYearFormat = "MM.YYYY";
let currentData = [];
let currentOriginalData = [];

const ReportMonitoringAchievement = (props) => {
  const userLogin = props.userLogin;
  const token = props.token;

  let initHeaderInput = {
    order_month: '',
    from: '',
    to: '',
    plant_id: "",
  }

  let initHeaders = [
    'Plant',
    'Order No',
    'Material',
    'Description',
    'Status',
    'Target Qty'
  ];

  let initColumns = [
    {
      type: 'text',
      readOnly: true,
      data: 'plant_id',
      width: 80,
      className: 'htCenter text-dark'
    },
    {
      type: 'text',
      data: 'order_no',
      readOnly: true,
      width: 120,
      className: 'text-dark'
    },
    {
      type: 'text',
      readOnly: true,
      data: 'material_number',
      width: 100,
      className: 'htCenter text-dark'
    },
    {
      type: 'text',
      data: 'description',
      readOnly: true,
      width: 280,
      className: 'htLeft text-dark'
    },
    {
      type: 'text',
      readOnly: true,
      data: 'status_name',
      width: 150,
      className: 'text-dark'
    },
    {
      type: 'text',
      readOnly: true,
      data: 'target_qty',
      width: 80,
      className: 'htCenter text-dark'
    },
  ]

  let tableRef = useRef(null);

  let initSetting = {
    fixedColumnsStart: 6,
    hiddenColumns: {
      columns: [],
    },
  };
  let hotSettings = {...initSetting}

  const [headerInput, setHeaderInput] = useState(initHeaderInput);
  const [loading, setLoading] = useState(false);
  const [listPlant, setListPlant] = useState([]);
  const [searchFilter, setSearchFilter] = useState('');
  // eslint-disable-next-line
  const [productionOrders, setProductionOrders] = useState([]);
  
  // Hands on table init
  const [hotHeaders, sethotHeaders] = useState(initHeaders);
  const [hotColumns, setHotColumns] = useState(initColumns);
  const [hotData, setHotData] = useState([]);
  const [hotMergeCell, setHotMergeCell] = useState([]);

  const getPlant = async () => {
    const userRoles = [...new Set(localStorage.get('user-role').map(item => item.roles_id)) ]
    let result = [];
    if(userLogin?.plant_id){
      let userRole = userRoles[0]
      let reqQuery = qs.stringify({ plant_id: userLogin?.plant_id });

      // if staff nabati then get company
      if( !userRole.includes('vendor-plant') ){
        const user_companies = userLogin?.company_id;
        if(user_companies){
          const company_ids = user_companies?.split(",") || ['-']
          reqQuery = qs.stringify({ company_ids: company_ids });
        }
      }
      
      const plantRes = await xhr.get('active', `/plant?${reqQuery}`, {
        headers: {'Authorization': `Bearer ${token}`},
      });

      if(plantRes.data) {
        result = plantRes.data.map(item => {
          return {
            ...item,
            value: item.plant_id,
            label: `${item.plant_id} - ${item.plant_desc}`,
          }
        })
      }

    }
    return result
  }

  useEffect( () => {
    getPlant().then(data => {
      setListPlant(data)
    })
    // eslint-disable-next-line
  }, [userLogin]);

  useEffect( () => {
    const hot = tableRef?.current?.hotInstance;
    const filteredData = currentOriginalData.filter( row => {
      return (row?.material_desc.toString().toLowerCase()).includes(searchFilter.toString().toLowerCase()) || 
      (row?.order_num.toString().toLowerCase()).includes(searchFilter.toString().toLowerCase())
    });
    
    if(searchFilter){
      currentData = filteredData;
      setHotData(currentData)
    }else{
      currentData = [...currentOriginalData];
      setHotData(currentData)
    }
    hot.loadData(currentData);

    // eslint-disable-next-line
  }, [searchFilter])

  const validateFormHeader = async () => {
    const startDate = moment(headerInput.from);
    let endDate = moment(headerInput.to);
    let isValid = startDate < endDate && (headerInput.order_month && headerInput.plant_id && headerInput.from && headerInput.to);
    let msg = startDate >= endDate ? 'Start date should be lower than end date' : ( !headerInput.order_month || !headerInput.plant_id || !headerInput.from || headerInput.to ? 'Field cannot be empty!' : '' );

    if(!isValid){
      await Swal.fire(
        'Error',
        msg,
        'error'
      )
    }
    return isValid;
  }

  const generateTable = async (e) => {
    if(e) {
      e.preventDefault();
    }
    const isValid = await validateFormHeader();
    if(isValid) {
      
      try {
        setLoading(true);
        let orderMonth = moment(headerInput.order_month);
        const startDate = moment(headerInput.from);
        let fromDate = moment(headerInput.from);
        let endDate = moment(headerInput.to);
        let plant_id = headerInput.plant_id;
        var duration = moment.duration(endDate.diff(startDate));
        var days = duration.asDays();
        let newHeaders = [];
        let newColumns = [];
        let newMergeCells = [];

        const reqQuery = qs.stringify({
          month: orderMonth.format('M'),
          year: orderMonth.format('YYYY'),
          plant_id: plant_id,
          from: startDate.format(DefaultDateFormat),
          to: endDate.format(DefaultDateFormat),
        })

        const poRes = await xhr.get('active', `/production-order/planning-report?${reqQuery}`);
        if(poRes.data){
          // generate column headers date and newcolumns dates 
          for (let i = 0; i <= days; i++) {
            newHeaders = [
              ...newHeaders,
              fromDate.format(DefaultDateFormat)
            ]
            newColumns = [
              ...newColumns,
              {
                type: 'numeric',
                data: fromDate.format(DefaultDateFormat),
                width: 100,
                className: 'htLeft text-dark',
                readOnly: true,
              }
            ]
    
            // add 1 days
            fromDate.add(1, 'days');
          }

          newHeaders = [ ...initHeaders, ...newHeaders]
          newColumns = [ ...initColumns, ...newColumns]
          const PoData = poRes.data;

          PoData.forEach( (po, idx) => {
            if( idx % 6 === 0 && PoData.length > 0){
              newMergeCells.push({ row: idx, col: 0, rowspan: 6, colspan: 1 })
              newMergeCells.push({ row: idx, col: 1, rowspan: 6, colspan: 1 })
              newMergeCells.push({ row: idx, col: 2, rowspan: 6, colspan: 1 })
              newMergeCells.push({ row: idx, col: 3, rowspan: 6, colspan: 1 })
            }
          });

          currentOriginalData = [...PoData];
          currentData = [...PoData];

          setHotColumns([...newColumns])
          sethotHeaders([...newHeaders])
          setHotData([...PoData])
          setHotMergeCell(newMergeCells)
          
        }else{
          setProductionOrders([])
        }

      } catch (error) {
        
      } finally {
        setLoading(false)
      }
    }
  }

  const downloadExcel = async (e) => {
    const isValid = await validateFormHeader();

    if(isValid){

      if(currentData.length === 0){
        await Swal.fire(
          'Error',
          'Data is empty!',
          'error'
        )
        return false;
      }

      setLoading(true);
      let orderMonth = moment(headerInput.order_month);
      const startDate = moment(headerInput.from);
      let endDate = moment(headerInput.to);

      const reqQuery = qs.stringify({
        plant_id: headerInput.plant_id,
        month: orderMonth.format('M'),
        year: orderMonth.format('YYYY'),
        from: startDate.format(DefaultDateFormat),
        to: endDate.format(DefaultDateFormat),
      })

      const url = xhr.setUrl(
        `/export-excel/planning-report?${reqQuery}`
      )
      window.open(url, '_blank');
    }
    setLoading(false);
    return;
  }

  return (
    <>
      <Loader loading={loading}/>
      <div className="container-fluid bg-soft-gray h-100 my-3">
        <div className="container-content mx-3 py-4">
          <div className="card pb-4">

            <div className='card-title text-center bg-soft-tosca pt-2'>
              <h5 className='text-white'><strong>Report Monitoring Production Achievement</strong></h5>
            </div>
            
            {/* form planning horizon */}
            <div className="row m-0">
              <div className='col-sm-12 col-md-12 col-xl-6 wrapper-goodreceive'>
                {/* form rph */}
                <form
                  onSubmit={generateTable}
                  >
                  <div className="card m-xl-4 p-3">
                    <strong className='mb-3'><u>Planning Horizon</u></strong>
                    
                    {/* Month and Year */}
                    <div className="mb-3 row m-0">
                      {/* Order month */}
                      <div className='col-sm-6 col-md-6 d-flex p-0'>
                        <label htmlFor="inputActualDate" className="col-sm-3 col-form-label">Order Month <span className="text-danger">*</span></label>
                        <div className="col-sm-8">
                          <div className="DayPicker-input-group form-floating mb-1">
                            <DayPickerInput 
                              format={MonthYearFormat}
                              formatDate={formatDate}
                              parseDate={parseDate}
                              placeholder={MonthYearFormat}
                              dayPickerProps={{
                                todayButton: 'Go To Today',
                              }}
                              showMonthYearPicker
                              showFullMonthYearPicker
                              keepFocus={false} 
                              id="inputActualDate"
                              onDayChange = {
                                (day) => setHeaderInput({
                                  ...headerInput,
                                  order_month: formatDate(day)
                                })
                              }
                              
                            />
                            <span className="DayPicker-input-group-text bg-white">
                            <svg className="bi" width="20" height="20" fill="currentColor">
                            <use xlinkHref={`${BootIcon}#calendar3`}/>
                            </svg>
                          </span>
                          </div>
                        </div>
                      </div>

                      <div className='col-sm-6 col-md-6 d-flex p-0'>
                        
                      </div>
                    </div>

                    {/* start and to date */}
                    <div className="mb-3 row m-0">
                      {/* from date */}
                      <div className='col-sm-6 col-md-6 d-flex p-0'>
                        <label htmlFor="inputActualDate" className="col-sm-3 col-form-label">From <span className="text-danger">*</span></label>
                        <div className="col-sm-8">
                          <div className="DayPicker-input-group form-floating mb-1">
                            <DayPickerInput 
                              format={DefaultDateFormat}
                              formatDate={formatDate}
                              parseDate={parseDate}
                              placeholder={DefaultDateFormat}
                              dayPickerProps={{
                                todayButton: 'Go To Today',
                              }}
                              keepFocus={false} 
                              id="inputActualDate"
                              onDayChange = {
                                (day) => setHeaderInput({
                                  ...headerInput,
                                  from: formatDate(day)
                                })
                              }
                              
                            />
                            <span className="DayPicker-input-group-text bg-white">
                            <svg className="bi" width="20" height="20" fill="currentColor">
                            <use xlinkHref={`${BootIcon}#calendar3`}/>
                            </svg>
                          </span>
                          </div>
                        </div>
                      </div>
                      {/* to date */}
                      <div className='col-sm-6 col-md-6 d-flex p-0'>
                        <label htmlFor="inputActualDate" className="col-sm-3 px-3 col-form-label">To <span className="text-danger">*</span></label>
                        <div className="col-sm-8">
                          <div className="DayPicker-input-group form-floating mb-1">
                            <DayPickerInput 
                              format={DefaultDateFormat}
                              formatDate={formatDate}
                              parseDate={parseDate}
                              placeholder={DefaultDateFormat}
                              dayPickerProps={{
                                todayButton: 'Go To Today',
                              }}
                              keepFocus={false} 
                              id="inputActualDate"
                              onDayChange = {
                                (day) => setHeaderInput({
                                  ...headerInput,
                                  to: formatDate(day)
                                })
                              }
                            />
                            <span className="DayPicker-input-group-text bg-white">
                            <svg className="bi" width="20" height="20" fill="currentColor">
                            <use xlinkHref={`${BootIcon}#calendar3`}/>
                            </svg>
                          </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Plant */}
                    <div className="mb-3 row m-0">
                      <div className='col-sm-6 col-md-6 d-flex p-0'>
                        <label htmlFor="input-memo" className="col-sm-3 col-form-label">Plant</label>
                        {/* select dropdown table */}
                        <Select 
                          options={listPlant} 
                          isClearable={true}
                          styles={styleSelect}
                          className="w-100"
                          menuPosition="absolute"
                          placeholder="Choose Plant"
                          id="plant_id"
                          name="plant_id"
                          
                          onChange = {
                            (event) => {
                              setHeaderInput({
                                ...headerInput,
                                plant_id: event?.value || '' 
                              })
                            }
                          }
                          />
                      </div>
                      <div className='col-sm-6 col-md-6 d-flex justify-content-end p-0'>
                        <button type='submit' className='btn btn-sm btn-primary mb-3 py-1 px-4'>Go</button>
                      </div>
                    </div>
                    
                  </div>
                </form>
              </div>
            </div>

            {/* filter and buttons */}
            <div className='row mx-xl-4 mb-sm-3'>
              {/* buttons */}
              <div className='col-8 p-4'>
                <button className='btn btn-sm btn-primary' onClick={downloadExcel}>Download</button>
              </div>
              {/* Filter */}
              <div className='col-4 p-4'>
                <input 
                  className='form-control' 
                  placeholder='Search...'
                  onChange = {
                    (e) => {
                      setSearchFilter(e?.value || '' )
                    }
                  }
                  onKeyUp={
                    (e) => {
                      if(e.keyCode === 13){
                        setSearchFilter(e?.target.value || '' )
                      }
                    }
                  }
                  />
              </div>
            </div>

            {/* Handsontable */}
            <div className="row">
            
              <div className='col-12'>
                <div className='table-responsive'>
                  <HandsontableComponent
                    headers={hotHeaders} 
                    columns={hotColumns}
                    data={hotData}
                    headerInput={headerInput}
                    setLoading={setLoading}
                    setting={hotSettings}
                    tableRef={tableRef}
                    mergeCell={hotMergeCell}
                  />
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}

export default ReportMonitoringAchievement