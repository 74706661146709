import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import qs from 'querystring';

import {xhr} from '../../utils';
import Select from 'react-select';
import 'handsontable/dist/handsontable.full.css';

import 'react-day-picker/lib/style.css';
import '../../assets/css/plant.css';
import BootIcon from 'bootstrap-icons/bootstrap-icons.svg';

import {formatDate, parseDate} from 'react-day-picker/moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import moment from 'moment';
import Swal from 'sweetalert2';
import HandsontableMps from './HandsontableMps';
import Loader from '../../common/Loader';

const styleSelect = {
  input: (e) => ({
      width: 100
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 300, // Adjust z-index here
  }),
};

const DefaultDateFormat = "DD.MM.YYYY";

const MasterProductionSchedule = (props) => {
  const token = props.token;
  const initModal = {
    title: '',
    action: '',
  }

  let initInputHeader = {
    from: '',
    to: '',
    plant_id: "",
  }
  let initColHeadersMps = [
    'ID',
    'Plant',
    'Material',
    'Description',
    'UOM',
    'Ver',
    'A', //Active
  ];
  let initColumnsMps = [
    {
      type: 'text',
      readOnly: true,
      data: 'id',
      width: 100,
      className: 'htCenter'
    },
    {
      type: 'text',
      readOnly: true,
      data: 'plant_id',
      width: 80,
      className: 'htCenter'
    },
    {
      type: 'dropdown',
      data: 'material_number',
      source: [],
      width: 120,
    },
    {
      type: 'text',
      data: 'description',
      readOnly: true,
      width: 280,
      className: 'htLeft'
    },
    {
      type: 'text',
      data: 'base_uom',
      readOnly: true,
      width: 80,
      className: 'htCenter'
    },
    {
      type: 'text',
      data: 'version',
      width: 100,
      className: 'htCenter',
      readOnly: true,
    },
    {
      type: 'checkbox',
      data: 'active',
      width: 50,
      className: 'htCenter',
      checkedTemplate: 1,
      uncheckedTemplate: 0,
      readOnly: true,
    },
  ]
  
  let materialMasterData = [];
  const userLogin = props.userLogin;
  const [inputFilter, setInputFilter] = useState('');
  const [loading, setLoading] = useState(false);
  const [headerInput, setHeaderInput] = useState(initInputHeader);
  const [listPlant, setListPlant] = useState([]);
  const [listMasterMaterial, setlistMasterMaterial] = useState([]);
  const [inputFile, setInputFile] = useState(null)
  
  const [colHeadersMps, setColHeadersMps] = useState(initColHeadersMps);
  const [defaultColumnMps, setDefaultColumnMps] = useState([]);
  const [columnsMps, setColumnMps] = useState(initColumnsMps);
  const [dataMps, setDataMps] = useState([]);

  // modal
  const [show, setShow] = useState(false);
  const [modal, setModal] = useState(initModal);
  const closeModal = () => setShow(false);
  const openModal = () => setShow(true);

  const getPlant = async () => {
    let result = [];    
    if(userLogin?.company_id){
      const user_companies = userLogin?.company_id;
      const company_ids = user_companies.split(",")
      const reqQuery = qs.stringify({ company_ids: company_ids })
      const plantRes = await xhr.get('active', `/plant?${reqQuery}`, {
        headers: {'Authorization': `Bearer ${token}`},
      });
      if(plantRes.data) {
        result = plantRes.data.map(item => {
          return {
            ...item,
            value: item.plant_id,
            label: `${item.plant_id} - ${item.plant_desc}`,
          }
        })
      }
      return result;
    }
    return []
  }

  const getMps = async (request) => {
    let result = {};
    const mpsRes = await xhr.post('active', `/mps/getAll`,
      request,
    {
      headers: {'Authorization': `Bearer ${token}`}
    });
    
    if(mpsRes.data) {
      result = mpsRes.data;
    }
    return result;
  }

  const getMasterMaterial = async (plant_id) => {
    let result = [];
    if(plant_id){
      //setlistMasterMaterial([])
      const reqQuery = qs.stringify({ material_type: 'FERT' })
      const requestMaterial = await xhr.get('active', `/master-material-vendor-plant/${plant_id}?${reqQuery}`, {
        headers: {'Authorization': `Bearer ${token}`}
      });
      if(requestMaterial.data) {
        result = requestMaterial.data;
        //setlistMasterMaterial(result)
      }
    }
    return await result;
  }

  // useEffect( () => {
  //   // getMasterMaterial(headerInput.plant_id)
  //   // eslint-disable-next-line
  // }, [headerInput.plant_id])
  
  const formHeaderValidation = () => {
    const startDate = moment(headerInput.from);
    let endDate = moment(headerInput.to);
    return startDate < endDate && (headerInput.plant_id && headerInput.from && headerInput.to);
  }

  const validateFormHeader = async () => {
    const startDate = moment(headerInput.from);
    let endDate = moment(headerInput.to);
    let isValid = formHeaderValidation();
    let msg = startDate >= endDate ? 'Start date should be lower than end date' : (!headerInput.plant_id || !headerInput.from || headerInput.to ? 'Field cannot be empty!' : '');

    if(!isValid){
      await Swal.fire(
        'Error',
        msg,
        'error'
      )
    }
    return isValid;
  }

  const generateTable = async () => {
    const startDate = moment(headerInput.from);
    let fromDate = moment(headerInput.from);
    let endDate = moment(headerInput.to);
    let plant_id = headerInput.plant_id;
    const isValid = await validateFormHeader();

    if(isValid){
      try {
        setLoading(true);
        await setlistMasterMaterial([]);
        
        materialMasterData = await getMasterMaterial(plant_id).catch( error => {
          throw error
        });
        const material_ids = materialMasterData.map(item => +item.material_number )

        const data = await getMps({
          from: startDate.format(DefaultDateFormat),
          to: endDate.format(DefaultDateFormat),
          plant_id: headerInput.plant_id,
          with: ['details'],
        }).catch( error => {
          throw error
        });
        const existingMaterialIdMps = data.map( item => +item.material_number );
        
        const filteredMaterial = materialMasterData.filter(item => !existingMaterialIdMps.includes(+item.material_number));
        const new_material_ids = filteredMaterial.map(item => +item.material_number)

        await setlistMasterMaterial(materialMasterData)

        console.log('result material_ids', material_ids, 'existingMaterialIdMps', existingMaterialIdMps)
        console.log('filteredMaterial', filteredMaterial);

        // generate columns 
        let newColumnMps = [...initColumnsMps];
        const indexPlant = newColumnMps.findIndex(x => x.data ==="plant_id");
        const indexMaterial = newColumnMps.findIndex(x => x.data ==="material_number");
        let plant = newColumnMps[indexPlant];
        let material = newColumnMps[indexMaterial];
        plant = {
          ...plant,
          value: plant_id
        };
        material = {
          ...material,
          source: [...new_material_ids]
        };
        
        newColumnMps[indexPlant] = plant
        newColumnMps[indexMaterial] = material
        
        var duration = moment.duration(endDate.diff(startDate));
        var days = duration.asDays();
        let newHeaders = [];
        let newColumns = [];
        let newData = [];
        
        for (let i = 0; i <= days; i++) {
          newHeaders = [
            ...newHeaders,
            fromDate.format(DefaultDateFormat)
          ]
          newColumns = [
            ...newColumns,
            {
              type: 'numeric',
              data: fromDate.format(DefaultDateFormat),
              width: 100,
              className: 'htLeft'
            }
          ]
  
          // add 1 days
          fromDate.add(1, 'days');
        }
  
        for (let i = 0; i < material_ids.length || 0; i++) {
          let newRow = {};
          
          newColumnMps.forEach( (col, i) => {
            let newCol = {};
            newCol[col.data] = col.value || ''
            newRow = {
              ...newRow,
              ...newCol
            }
          })
  
          if(newRow) {
            newData = [
              ...newData,
              newRow
            ]
          }
        }
  
        console.log("column mps", newColumnMps)
        setColHeadersMps([
          ...initColHeadersMps,
          ...newHeaders
        ])
        
        setColumnMps([
          ...newColumnMps,
          ...newColumns
        ])
  
        setDefaultColumnMps(newColumnMps);

        let existingMps = [];
            
        if(data){
          data.forEach(item => {
            let newItem = { ...item };
            if(item?.details){
              (item.details).forEach(child => {
                newItem[child.datakey] = child.value
              })
            }
            existingMps = [
              ...existingMps,
              newItem
            ]
          });
        }

        const materialIdFiltered = material_ids.filter(item => !existingMaterialIdMps.includes(item))
        const newArray = [...newData];
        newArray.length = materialIdFiltered.length ?? 0
        
        setDataMps([
          ...existingMps,
          ...newArray
        ])


        // await getMasterMaterial(plant_id).then( async (result) => {
        //   const material_ids = result.map(material => +material.material_number )

        //   // generate columns 
        //   let newColumnMps = [...initColumnsMps];
        //   const indexPlant = newColumnMps.findIndex(x => x.data ==="plant_id");
        //   const indexMaterial = newColumnMps.findIndex(x => x.data ==="material_number");
        //   let plant = newColumnMps[indexPlant];
        //   let material = newColumnMps[indexMaterial];
        //   plant = {
        //     ...plant,
        //     value: plant_id
        //   };
        //   material = {
        //     ...material,
        //     source: [...material_ids]
        //   };
          
        //   newColumnMps[indexPlant] = plant
        //   newColumnMps[indexMaterial] = material
          
        //   var duration = moment.duration(endDate.diff(startDate));
        //   var days = duration.asDays();
        //   let newHeaders = [];
        //   let newColumns = [];
        //   let newData = [];
          
        //   for (let i = 0; i <= days; i++) {
        //     newHeaders = [
        //       ...newHeaders,
        //       fromDate.format(DefaultDateFormat)
        //     ]
        //     newColumns = [
        //       ...newColumns,
        //       {
        //         type: 'numeric',
        //         data: fromDate.format(DefaultDateFormat),
        //         width: 100,
        //         className: 'htLeft'
        //       }
        //     ]
    
        //     // add 1 days
        //     fromDate.add(1, 'days');
        //   }
    
        //   for (let i = 0; i < material_ids.length || 0; i++) {
        //     let newRow = {};
            
        //     newColumnMps.forEach( (col, i) => {
        //       let newCol = {};
        //       newCol[col.data] = col.value || ''
        //       newRow = {
        //         ...newRow,
        //         ...newCol
        //       }
        //     })
    
        //     if(newRow) {
        //       newData = [
        //         ...newData,
        //         newRow
        //       ]
        //     }
        //   }
    
        //   setColHeadersMps([
        //     ...initColHeadersMps,
        //     ...newHeaders
        //   ])
          
        //   setColumnMps([
        //     ...newColumnMps,
        //     ...newColumns
        //   ])
    
        //   setDefaultColumnMps(newColumnMps)
          
        //   await getMps({
        //     from: startDate.format(DefaultDateFormat),
        //     to: endDate.format(DefaultDateFormat),
        //     plant_id: headerInput.plant_id,
        //     with: ['details'],
        //   }).then( data =>{
        //     let existingMps = [];
        //     const existingMaterialIdMps = data.map( item => +item.material_number );
            
        //     if(data){
        //       data.forEach(item => {
        //         let newItem = { ...item };
        //         if(item?.details){
        //           (item.details).forEach(child => {
        //             newItem[child.datakey] = child.value
        //           })
        //         }
        //         existingMps = [
        //           ...existingMps,
        //           newItem
        //         ]
        //       });
        //     }

        //     // generete new row length
        //     // const numberOfElementsToRemove = existingMps.length;
        //     // const newArray = [...newData];
        //     // newArray.length = Math.max(newArray.length - numberOfElementsToRemove, 0);

        //     const materialIdFiltered = material_ids.filter(item => !existingMaterialIdMps.includes(item))
        //     const newArray = [...newData];
        //     newArray.length = materialIdFiltered.length ?? 0
            
        //     setDataMps([
        //       ...existingMps,
        //       ...newArray
        //     ])
    
        //   })
        // })

      } catch (error) {
        Swal.fire(
          'Error',
          error.message,
          'error'
        )
      } finally {
        setLoading(false);
      }
    }
    // eslint-disable-next-line
  }
  
  useEffect( () => {
    getPlant().then(data => {
      setListPlant(data)
    })
    // eslint-disable-next-line
  }, [userLogin]);

  const downloadExcel = async (e) => {
    const startDate = moment(headerInput.from);
    let endDate = moment(headerInput.to);
    const isValid = await validateFormHeader();

    if(isValid){ 
      setLoading(true);
      const reqParams = qs.stringify({
        plant_id: headerInput.plant_id,
        from: startDate.format(DefaultDateFormat),
        to: endDate.format(DefaultDateFormat),
      })

      const url = xhr.setUrl(
        `/export-excel/planning-mps?${reqParams}`
      )
      window.open(url, '_blank');
    }
    setLoading(false);
    return;
  }
  
  const notifyUser = async () => {
    const isValid = await validateFormHeader();
    if (isValid) {
      const startDate = moment(headerInput.from);
      const endDate = moment(headerInput.to);
      const reqBody = {
        plant_id: headerInput.plant_id,
        from: startDate.format(DefaultDateFormat),
        to: endDate.format(DefaultDateFormat),
      }
  
      setLoading(true)
      const mpsRes = await xhr.post(
          'active',
          `/mps/notify`,
          reqBody,
          {headers: {'Authorization': `Bearer ${token}`}}
        )
        .catch(err => {
          Swal.fire(
            'Error',
            err.message,
            'error'
          )
        })
        .finally(() => {
          setLoading(false)
        })
      
      if(mpsRes.status === 200) {
        Swal.fire(
          'Success',
          mpsRes?.message ?? 'Notification successfully sent to user emails',
          'success'
        )
        closeModal()
      }
  
      setLoading(false)
    }

    return;
  }

  const uploadExcel = async () => {
    try {
      if(typeof inputFile === 'object'){
        setLoading(true)
        const formData = new FormData();
        formData.append('excel', inputFile);

        const mpsExcel = await xhr.post(
            'active', 
            `/mps/upload`,
            formData,
            {
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
              }
            }
          ).catch(err => {
            throw new Error(err.message)
          })
        
        if(mpsExcel.data) {
          if(mpsExcel.data?.error){
            throw new Error(mpsExcel.data?.message)
          }else{
            
            Swal.fire(
              'Success',
              'Successfully uploaded',
              'success'
            )
            closeModal()
          }
          // re-generate table if form valid
          if(headerInput.plant_id && headerInput.from && headerInput.to) generateTable()
          else {
            // if empty header input => get uploaded RPH 
            if(!formHeaderValidation()) {
              // Upload Excel file
              // generate table according to the uploaded file;
              const startDate = moment(mpsExcel.data.from, DefaultDateFormat);
              let fromDate = moment(mpsExcel.data.from, DefaultDateFormat);
              let endDate = moment(mpsExcel.data.to, DefaultDateFormat);

              var duration = moment.duration(endDate.diff(startDate));
              var days = duration.asDays();

              let newHeaders = [];
              let newDefaultColumns = [ ...initColumnsMps ];

              newDefaultColumns[2] = {
                data: 'material_number',
                type: 'text',
                readOnly: true,
                width: 120,
              };

              let newColumns = [];
              let newData = [];

              // generate column headers date and newcolumns dates 
              for (let i = 0; i <= days; i++) {
                newHeaders = [
                  ...newHeaders,
                  fromDate.format(DefaultDateFormat)
                ]
                newColumns = [
                  ...newColumns,
                  {
                    type: 'numeric',
                    data: fromDate.format(DefaultDateFormat),
                    width: 100,
                    className: 'htLeft'
                  }
                ]
        
                // add 1 days
                fromDate.add(1, 'days');
              }


              // combine default and new hot init
              newHeaders = [ ...initColHeadersMps, ...newHeaders]
              newColumns = [ ...newDefaultColumns, ...newColumns];
              
              const mpsIds = mpsExcel.data.data.map(item => item.id)
              
              await getMps({
                in: {
                  id: mpsIds
                },
                from: mpsExcel.data.from,
                to: mpsExcel.data.to,
                with: ['details'],
              }).then( async data => {
                const newMpsData = [];
                let newDataTemplate = {};
                newColumns.forEach( (col, i) => {
                  newDataTemplate[col.data] = col?.value || '';
                })
                if(data){
                  data.forEach((item, idx) => {
                    let newItem = { ...newDataTemplate, ...item };
                    if(item?.details){
                      (item.details).forEach(child => {
                        newItem[child.datakey] = child.value ?? ''
                      })
                    }
                    delete newItem['details']
                    newMpsData.push(newItem)
                  });
                }

                const numberOfElementsToRemove = newMpsData.length;
                const newArray = [...newData];
                newArray.length = Math.max(newArray.length - numberOfElementsToRemove, 0);
                
                const currentMpsData = [
                  ...newMpsData,
                  ...newArray
                ];

                setDataMps([...currentMpsData])
                setColHeadersMps([...newHeaders])
                setColumnMps([...newColumns])
                setDefaultColumnMps([...newColumns])
              })
            }
          }
        }

      }else{
        throw new Error("error format")
      }
    } catch (error) {
      Swal.fire(
        'Error',
        error.message,
        'error'
      )
    } finally {
      setLoading(false)
    }
    
  }

  return (
    <>
      <Loader loading={loading}/>
      <div className="container-fluid bg-soft-gray h-100 my-3">
        <div className="container-content mx-3 py-4">
          <div className="card pb-4">
            <div className='card-title text-center bg-soft-tosca pt-2'>
              <h5 className='text-white'><strong>Master Production Schedule (MPS)</strong></h5>
            </div>
            {/* form planning horizon */}
            <div className="row m-0">
              <div className='col-sm-12 col-md-12 col-xl-6 wrapper-goodreceive'>
                {/* form mps */}
                <form
                  onSubmit={(e) => { e.preventDefault(); generateTable() } }
                  >
                  <div className="card m-xl-4 p-3">
                    <strong className='mb-3'><u>Planning Horizon</u></strong>
                    {/* star and to date */}
                    <div className="mb-3 row m-0">
                      {/* from date */}
                      <div className='col-sm-6 col-md-6 d-flex p-0'>
                        <label htmlFor="inputActualDate" className="col-sm-3 col-form-label">From <span className="text-danger">*</span></label>
                        <div className="col-sm-8">
                          <div className="DayPicker-input-group form-floating mb-1">
                            <DayPickerInput 
                              format={DefaultDateFormat}
                              formatDate={formatDate}
                              parseDate={parseDate}
                              placeholder={DefaultDateFormat}
                              dayPickerProps={{
                                todayButton: 'Go To Today',
                              }}
                              keepFocus={false} 
                              id="inputActualDate"
                              onDayChange = {
                                (day) => setHeaderInput({
                                  ...headerInput,
                                  from: formatDate(day)
                                })
                              }
                              
                            />
                            <span className="DayPicker-input-group-text bg-white">
                            <svg className="bi" width="20" height="20" fill="currentColor">
                            <use xlinkHref={`${BootIcon}#calendar3`}/>
                            </svg>
                          </span>
                          </div>
                        </div>
                      </div>
                      {/* to date */}
                      <div className='col-sm-6 col-md-6 d-flex p-0'>
                        <label htmlFor="inputActualDate" className="col-sm-3 px-3 col-form-label">To <span className="text-danger">*</span></label>
                        <div className="col-sm-8">
                          <div className="DayPicker-input-group form-floating mb-1">
                            <DayPickerInput 
                              format={DefaultDateFormat}
                              formatDate={formatDate}
                              parseDate={parseDate}
                              placeholder={DefaultDateFormat}
                              dayPickerProps={{
                                todayButton: 'Go To Today',
                              }}
                              keepFocus={false} 
                              id="inputActualDate"
                              onDayChange = {
                                (day) => setHeaderInput({
                                  ...headerInput,
                                  to: formatDate(day)
                                })
                              }
                            />
                            <span className="DayPicker-input-group-text bg-white">
                            <svg className="bi" width="20" height="20" fill="currentColor">
                            <use xlinkHref={`${BootIcon}#calendar3`}/>
                            </svg>
                          </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Plant */}
                    <div className="mb-3 row m-0">
                      <div className='col-sm-6 col-md-6 d-flex p-0'>
                        <label htmlFor="input-memo" className="col-sm-3 col-form-label">Plant</label>
                        {/* select dropdown table */}
                        <Select 
                          options={listPlant} 
                          isClearable={true}
                          styles={styleSelect}
                          className="w-100"
                          menuPosition="absolute"
                          placeholder="Choose Plant"
                          id="plant_id"
                          name="plant_id"
                          
                          onChange = {
                            (event) => {
                              setHeaderInput({
                                ...headerInput,
                                plant_id: event?.value || '' 
                              })
                            }
                          }
                          />
                      </div>
                      <div className='col-sm-6 col-md-6 d-flex justify-content-end p-0'>
                        <button type='submit' className='btn btn-sm btn-primary mb-3 py-1 px-4'>Go</button>
                      </div>
                    </div>
                    
                  </div>
                </form>
              </div>
            </div>

            {/* buttons */}
            <div className='mb-3 row m-xl-4 m-md-2'>
              <div className='col-8 grid'>
                <button className='btn btn-sm btn-primary' onClick={downloadExcel} >Download</button>
                <button className='btn btn-sm btn-primary mx-3' onClick={() => {
                  openModal()
                  setModal({
                    ...modal,
                    title: 'Upload',
                    action: 1
                  })
                }}>Upload</button>
                {/* <button className='btn btn-sm btn-primary mx-3' data-bs-toggle="modal" data-bs-target="#exampleModal">upload</button> */}
                <button className='btn btn-sm btn-primary mx-5' onClick={notifyUser}>Resend notif</button>
              </div>
              
              {/* <div className='col-4'>
                
              </div> */}
            </div>
            {/* Filter */}
            <div className='row mx-xl-4 mb-sm-3 justify-content-end'>
              <div className='col-4'>
                <input 
                  className='form-control' 
                  placeholder='Search...'
                  onChange = {
                    (e) => {
                      setInputFilter(e?.value || '' )
                    }
                  }
                  onKeyUp={
                    (e) => {
                      if(e.keyCode === 13){
                        setInputFilter(e?.target.value || '' )
                      }
                    }
                  }
                  />
              </div>
            </div>

            {/* Table */}
            <div className='card m-xl-4'>
              <div className='table-responsive'>
                <HandsontableMps 
                  headers={colHeadersMps} 
                  columns={columnsMps} 
                  data={dataMps} 
                  masterMaterials={listMasterMaterial}
                  filter={inputFilter}
                  headerInput={headerInput}
                  defaultColumnMps={defaultColumnMps}
                  setLoading={setLoading}
                  generateTable={generateTable}
                  />
              </div>
            </div>

            {/* Modal */}
            <Modal 
              show={show} 
              onHide={closeModal}
              backdrop="static"
              keyboard={false}
              size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              >
              <Modal.Header closeButton>
                <Modal.Title>{modal.title}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {modal.action === 1 ? (
                  <>
                    {/* Upload excel */}
                    <div className="mb-3">
                      <label htmlFor="input-upload" className="form-label">Select file</label>
                      <input className="form-control form-control" id="input-upload" type="file" accept=".xls, .xlsx" onChange={(e) => {
                        if(e.target.files){
                          setInputFile(e.target.files[0])
                        }
                      } }/>
                    </div>
                  </>
                ) : modal.action === 2 ? ( 
                  <>
                    Method changed
                  </>
                ) : (
                  <div>No Action!</div>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>
                  Close
                </Button>
                { modal.action === 1 ? (
                  <Button variant="primary" onClick={uploadExcel}>
                    Save
                  </Button>
                ) : modal.action === 2 ? (
                  <Button variant="primary" onClick={notifyUser}>
                    Send
                  </Button>
                ) : (
                  <></>
                )}
              </Modal.Footer>
            </Modal>

          </div>
        </div>
      </div>
    </>
  )
}

export default MasterProductionSchedule