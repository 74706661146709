import React, { useEffect, useState } from "react";

import Sidebar from "../../common/Sidebar";
import Navbar from "../../common/Navbar";
import BreadCumb from "../../common/BreadCumb";

import localStorage from "local-storage";
// const localStorage = require("local-storage");

import MasterProductionSchedule from "./MasterProductionSchedule";
import { getToken } from "../../utils";
import RencanaProduksiHarian from "./RencanaProduksiHarian";
import ReportMonitoringAchievement from "./ReportMonitoringAchievement";

const token = getToken()

const PlanningPage = (props) => {
  const urlProfile = props.match.url;
  const [userLogin, setUserLogin] = useState({});
  
  const getUser = () => {
    const userInfo = localStorage.get("user-info");
    if(userInfo){
      setUserLogin(userInfo)
    }
  }

  const fetchData = async () => {
    getUser()
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Sidebar/>
      <Navbar/>
      <BreadCumb/>
      {urlProfile === "/planning/mps" ? (
        <>
          <MasterProductionSchedule {...props} userLogin={userLogin} token={token}/>
        </>
      ) : urlProfile === "/planning/rph" ? (
        <>
          <RencanaProduksiHarian {...props} userLogin={userLogin} token={token}/>
        </>
      ) : (
        <ReportMonitoringAchievement {...props} userLogin={userLogin} token={token}/>
      )}
    </div>
  );
};

export default PlanningPage;
