import React from 'react'

const Loader = ({loading}) => {
  return (
    <>
        <div id='loader' className={loading ? '' : 'd-none'}>
            <div className='content d-flex justify-content-center align-items-center d-grid gap-3'>
                <div className="spinner-grow text-primary spinner-grow-sm" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                <div className="spinner-grow text-primary spinner-grow-sm" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                <div className="spinner-grow text-primary spinner-grow-sm" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                <div className='ml-3 h5'>... Loading</div>
            </div>
        </div>
    </>
  )
}

export default Loader